import Vue from "vue";
import Vuex from "vuex";
import Country from "./Country";
import Login from "./Login";
import YearGroup from "./YearGroup";
import Role from "./Role";
import Parent from "./Parent";
import GlobalUsers from "./GlobalUsers";
import Grapheme from "./Grapheme";
import Words from "./Words";
import School from "./School";
import Staff from "./StaffMembers";
import SpellingList from "./SpellingList";
import Class from "./Class";
import Pupil from "./Pupil";
import PhonicScheme from "./PhonicScheme";
import PhonicList from "./PhonicList";
import EndOfStage from "./EndOfStage";
import Children from "./Children";
import Sentences from "./Sentences";
import SpaceRace from "./SpaceRace";
import Shared from "./Shared";
import StagesandSteps from "./StagesandSteps";
import Group from "./Group";
import Account from "./Account";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    itemPerPage: 0,
    apiResponseStatus: false,
    apiResponseMessage: "",
  },
  modules: {
    Country,
    Login,
    YearGroup,
    Role,
    Parent,
    Grapheme,
    Words,
    GlobalUsers,
    School,
    Staff,
    SpellingList,
    Class,
    Pupil,
    PhonicScheme,
    PhonicList,
    EndOfStage,
    Children,
    Sentences,
    SpaceRace,
    Shared,
    StagesandSteps,
    Group,
    Account,
  },
  getters: {
    getItemsPerPage: (state) => state.itemPerPage,
    getAPIResponseStatus: (state) => state.apiResponseStatus,
    getAPIResponseMessage: (state) => state.apiResponseMessage,
  },
  mutations: {
    setItemPerPage(state, payload) {
      state.itemPerPage = payload;
    },
    setAPIResponseStatus(state, payload) {
      state.apiResponseStatus = payload;
    },
    setAPIResponseMessage(state, payload) {
      state.apiResponseMessage = payload;
    },
  },
});
