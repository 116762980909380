/* eslint-disable no-unused-vars */
import {
  makeApiCall,
  PostFormData,
  PutFormData,
  Restore,
  Retire,
} from "../apiRequest";

export default {
  state: {
    allGroupListBySchool: [],
    allGroupListBySchoolFull: [],
    allGroupListByUser: [],
    allGroupListByStaff: [],
    allGroupListBySchools: [],
    allRetiredGroupListByStaff: [],
    allRetiredGroupListBySchool: [],
  },
  getters: {
    getAllGroupsBySchool: (state) => state.allGroupListBySchool,
    getAllGroupsBySchoolFull: (state) => state.allGroupListBySchoolFull,
    getAllGroupsListByUser: (state) => state.allGroupListByUser,
    GetAllGroupByStaff: (state) => state.allGroupListByStaff,
    GetAllGroupBySchool: (state) => state.allGroupListBySchools,
    GetAllRetiredGroupByStaff: (state) => state.allRetiredGroupListByStaff,
    GetAllRetiredGroupBySchool: (state) => state.allRetiredGroupListBySchool,
  },
  actions: {
    async getAllRetiredGroupByStaff({ commit, rootState }, payload) {
      try {
        const response = await makeApiCall(
          `Group/GetAllRetiredGroupsByStaff?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}&creatorStaffId=${payload.creatorStaffId}`,
          `GET`
        );

        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data.groups.length >= 0) {
              commit("setAllRetiredGroupByStaff", res.data.groups);
              commit("setItemPerPage", res.data.totalItems);
            }
          }
        }
        return response.data.classes;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllRetiredGroupBySchool({ commit, rootState }, payload) {
      try {
        const response = await makeApiCall(
          `Group/GetAllRetiredGroupsBySchool?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}&schoolId=${payload.creatorStaffId}`,
          `GET`
        );

        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data.groups.length >= 0) {
              commit("setAllRetiredGroupBySchool", res.data.groups);
              commit("setItemPerPage", res.data.totalItems);
            }
          }
        }
        return response.data.classes;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllGroupByStaff({ commit, rootState }, payload) {
      try {
        const response = await makeApiCall(
          `Group/GetAllGroupsByStaff?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}&creatorStaffId=${payload.creatorStaffId}`,
          `GET`
        );

        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data.groups.length >= 0) {
              commit("setAllGroupByStaff", res.data.groups);
              commit("setItemPerPage", res.data.totalItems);
            }
          }
        }
        return response.data.groups;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllGroupBySchool({ commit, rootState }, payload) {
      try {
        const response = await makeApiCall(
          `Group/GetAllGroupsBySchool?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}&schoolId=${payload.creatorStaffId}`,
          `GET`
        );

        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data.groups.length >= 0) {
              commit("setAllGroupBySchools", res.data.groups);
              commit("setItemPerPage", res.data.totalItems);
            }
          }
        }
        return response.data.groups;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllGroupsListByUser({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Group/GetGroupList?userId=${userId}`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null && res.data.length >= 0) {
              commit("setAllGroupByUser", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllGroupsBySchool({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Group/GetGroupListBySchool?schoolId=${userId}`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null && res.data.length >= 0) {
              commit("setAllGroupBySchool", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllGroupsBySchoolFulls({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Group/GetGroupListBySchool?schoolId=${userId}`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null && res.data.length >= 0) {
              commit("setAllGroupBySchoolFull", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async retireGroup({ commit }, groupId) {
      try {
        const response = await makeApiCall(`Group/Retire/${groupId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async restoreGroup({ commit }, groupId) {
      try {
        const response = await makeApiCall(`Group/Restore/${groupId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async EditGroup({ commit }, payload) {
      try {
        const response = await makeApiCall(`Group`, `PUT`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async AddGroup({ commit }, payload) {
      try {
        const response = await makeApiCall(`Group`, `POST`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setItemPerPage(state, payload) {
      state.itemPerPage = payload;
    },
    setAllGroupBySchool(state, payload) {
      state.allGroupListBySchool = payload;
    },
    setAllGroupBySchoolFull(state, payload) {
      state.allGroupListBySchoolFull = payload;
    },
    setAllGroupByUser(state, payload) {
      state.allGroupListByUser = payload;
    },
    setAllGroupByStaff(state, payload) {
      state.allGroupListByStaff = payload;
    },
    setAllGroupBySchools(state, payload) {
      state.allGroupListBySchools = payload;
    },
    setAllRetiredGroupByStaff(state, payload) {
      state.allRetiredGroupListByStaff = payload;
    },
    setAllRetiredGroupBySchool(state, payload) {
      state.allRetiredGroupListBySchool = payload;
    },
  },
};
