import { makeApiCall } from "../apiRequest";

export default {
  state: {
    permissionList: [],
    roleActiveList: [],
    roleRetiredList: [],
    role: {},
    roleList: [],
  },
  getters: {
    getPermissions: (state) => state.permissionList,
    getRoles: (state) => state.roleActiveList,
    getRetiredRoles: (state) => state.roleRetiredList,
    getRole: (state) => state.role,
    GetRoleLists:(state) => state.roleList
  },
  actions: {
    async getPermission({ commit }) {
      try {
        const response = await makeApiCall(`Role/GetPermissions`, `GET`);
        if (response.status && response.data.status && response.data.data) {
          commit("setPermission", response.data.data);
        } 
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRoleList({ commit }) {
      try {
        const response = await makeApiCall(`Role/GetRolesList`, `GET`);
        if (response.status && response.data.status && response.data.data) {
          commit("SetRoleList", response.data.data);
        }        
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async getRole({ commit, rootState }, pagination) {
      try {
        const response = await makeApiCall(
          `Role?pageNo=${pagination.page}${
            pagination.pageSize != null
              ? `&pageSize=${pagination.pageSize}`
              : ""
          }${
            pagination.searchString != null
              ? `&searchString=${pagination.searchString}`
              : ""
          }`,
          "GET"
        );
        if (response.status && response.data.status && response.data.data && response.data.data.roles.length > 0) {
          const { roles, totalItems } = response.data.data;
          commit("setRoleActiveList", roles);
          commit("setItemPerPage", totalItems);
        }
        

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async getRetiredRoles({ commit, rootState }, pagination) {
      try {
        const response = await makeApiCall(
          `Role/GetRetiredRoles?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.roles.length >= 0) {
                commit("setRoleRetiredList", res.data.roles);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRoleById({ commit }, roleId) {
      try {
        const response = await makeApiCall(`Role/${roleId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setRole", res.data);
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    // eslint-disable-next-line
    async addRole({}, payload) {
      try {
        const response = await makeApiCall(`Role`, `POST`, payload);
        if (response.status) {
          if (response.status) var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async updateRole({}, payload) {
      try {
        const response = await makeApiCall(`Role`, `PUT`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retireRole({}, roleId) {
      try {
        const response = await makeApiCall(`Role/Retire/${roleId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async restoreRole({}, roleId) {
      try {
        const response = await makeApiCall(`Role/Restore/${roleId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setPermission(state, payload) {
      state.permissionList = payload;
    },
    setRoleActiveList(state, payload) {
      state.roleActiveList = payload;
    },
    setRoleRetiredList(state, payload) {
      state.roleRetiredList = payload;
    },
    setRole(state, payload) {
      state.role = payload;
    },
    SetRoleList (state, payload)
    {
      state.roleList = payload;
    }
  },
};
