import { makeApiCall } from "../apiRequest";

export default {
  state: {
    parentList: [],
    retireitemPerPage: 0,
    itemPerPage: 0,
    parent: [],
    ParentByID:[],
    parentRetireList: [],
    LinkedChildrenById:[],
    childrenitemperPage:0,
    token: null,
  },
  getters: {
    //getToken: (state) => state.token,
    getsingleParent: (state) => state.parent,
    getParents: (state) => state.parentList,
    getItemsPerPage: (state) => state.itemPerPage,
    getRetireItemsPerPage: (state) => state.retireitemPerPage,
    GetRetireParents: (state) => state.parentRetireList,
    GetParentByIds:(state)=> state.ParentByID,
   GetLinkedChildrenByIds:(state)=> state.LinkedChildrenById,
   GetItemPerPageChildren:(state) => state.childrenitemperPage,
  },
  actions: {
    async getParent({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `Parent?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}`,
          `GET`
        );
        if (response.status) var res = response.data;
        if (res.status && res.data && res.data.parents && res.data.parents.length >= 0) {
          commit("setparentList", res.data.parents);
          commit("setItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetRetireParent({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `Parent/GetAllRetiredParents?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}`,
          `GET`
        );
        if (response.status) var res = response.data;
        if (res.status && res.data != null && res.data.parents.length >= 0) {
          commit("setretireparentList", res.data.parents);
          commit("setRetireItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getParentById({ commit }, ParentId) {
      try {
        const response = await makeApiCall(`Parent/${ParentId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("SetParentById", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
       // eslint-disable-next-line
    async LoginParentById({ commit }, parentId) {
      try {
        const response = await makeApiCall(`Auth/LoginAsParent?parentId=${parentId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("SetParenttoken", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetLinkedChildrenByClassId({ commit, }, obj) {
      try {
        const response = await makeApiCall(
          
          `Parent/ViewChildren?pageNo=${obj.page}&pageSize=${obj.pageSize}&parentId=${obj.parentId}`,
          `GET`
        );
        if (response.status) var res = response.data;
        if (res.status && res.data != null && res.data.pupils.length >= 0) {
          commit("setLinkedChildList", res.data.pupils);
          commit("setChildItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    
     // eslint-disable-next-line
    async addparent({}, payload) {
      try {
        const response = await makeApiCall(`Parent`, `POST`, payload);
        if (response.status) var res = response.data;
        if (res.status) {
          return res;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
     // eslint-disable-next-line
    async updateparent({ }, payload) {
      try {
        const response = await makeApiCall(`Parent`, 'PUT', payload);
        if (response.status) var res = response.data;
        if (res.status) {
          return res;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
      // eslint-disable-next-line
    async updateRetireParent({ }, payload) {
      try {
        const response = await makeApiCall(`Parent/Retire/${payload}`, 'put');
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
      // eslint-disable-next-line
    async updateRestoreParent({ state }, payload) {
      try {
        const response = await makeApiCall(`Parent/Restore/${payload}`, 'put');
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    SetParenttoken(state, token) {
      state.token = token;
    },
    setparentList(state, payload) {
      state.parentList = payload;
    },
    setretireparentList(state, payload) {
      state.parentRetireList = payload;
    },
    setParent(state, payload) {
      state.parent = payload;
    },
    setItemPerPage(state, payload) {
      state.itemPerPage = payload;
    },
    setRetireItemPerPage(state, payload) {
      state.retireitemPerPage = payload;
    },
    ADD_PARENT(state, payload) {
      state.parents = payload;
    },
    SetParentById(state, payload)
    {
      state.ParentByID = payload;
    },
    setLinkedChildList(state, payload)
    {
      state.LinkedChildrenById = payload;
    },
    setChildItemPerPage(state, payload)
    {
      state.childrenitemperPage = payload;
    }
  },
};
