/* eslint-disable no-unused-vars */
import { 
  makeApiCall,
  PostFormData,
  PutFormData,      
 } from "../apiRequest";

export default {
    state: {
        endofstagedataList: [],
        retiredendofstageDataList: [],
        endofstagedata: {},
        endofstage: {},
        endofstageGraphemeListID: [],
        endofstageWordListID: [],
    },
    getters: {
        getEndOfStages: (state) => state.endofstagedataList,
        getRetiredEndOfStages: (state) => state.retiredendofstageDataList,
        getEndOfStageData: (state) => state.endofstagedata,
        getEndOfStage: (state) => state.endofstage,
        getEndofStageGraphemeIds: (state) => state.endofstageGraphemeListID,
        getEndofStageWordIds: (state) => state.endofstageWordListID,
    },
    actions: {
      async getEndOfStage({ commit, rootState }, pagination) {
        try {
          const response = await makeApiCall(
            `EndOfStageQuestion?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`,
            `GET`
          );
          if (response.status) var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.endofstage.length >= 0) {
                commit("setEndOfStageList", res.data.endofstage);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
        async getEndOfStageData({ commit }, obj) {
            try {
                const response = await makeApiCall(
                  `EndOfStageQuestion?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
                  `GET`
                );
                if (response.status) {
                  var res = response.data;
                  if (res.status) {
                    if (res.data != null) {
                      if (res.data.endOfStageQuestions.length >= 0) {
                        commit("setEndOfStageDataList", res.data.endOfStageQuestions);
                        commit("setItemPerPage", res.data.totalItems);
                      }
                    }
                  }
                }
        
                return response.data;
              } catch (error) {
                console.error(error);
                throw error;
              }
        },
        async getRetiredEndOfStageData({commit}, pagination){
            try {
                const response = await makeApiCall(
                  `EndOfStageQuestion/GetAllRetiredEndOfStageQuestions?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`,
                  `GET`
                );
                const res = response.data;
          if (res?.status && res.data?.endOfStageQuestions?.length >= 0) {
                        commit("setRetiredEndOfStageDataList", res.data.endOfStageQuestions);
                        commit("setItemPerPage", res.data.totalItems);
                      }
                    
                return response.data;
              } catch (error) {
                console.error(error);
                throw error;
              }
        },
        async getEndOfStageById({ commit }, endofstagedataId) {
          try {
            const response = await makeApiCall(`EndOfStageQuestion/${endofstagedataId}`, `GET`);
            if (response.status) {
              var res = response.data;
              if (res.status) {
                if (res.data != null) {
                  commit("setEndOfStage", res.data);
                }
              }
            }
    
            return response.data;
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
        async getEndofStagesGraphemeId({ commit }, payload) {
          try {
            const response = await makeApiCall(
              `EndOfStageQuestion/GetEndOfStageQuestionListByGrapheme`,
              `GET`
            );
            if (response.status) var res = response.data;
            if (res.status) {
              if (res.data != null) {
                if (res.data.length > 0) {
                  commit("setEndofStageIdGraphemeList", res.data);
                }
              }
            }
            return response.data;
          }
          catch (error) {
            console.error(error);
            throw error;
          }
        },
        async getEndofStagesWordId({ commit }, payload) {
          try {
            const response = await makeApiCall(
              `EndOfStageQuestion/GetEndOfStageQuestionListByWord`,
              `GET`
            );
            if (response.status) var res = response.data;
            if (res.status) {
              if (res.data != null) {
                if (res.data.length > 0) {
                  commit("setEndofStageIdWordList", res.data);
                }
              }
            }
            return response.data;
          }
          catch (error) {
            console.error(error);
            throw error;
          }
        },
        async addEndOfStage({ commit }, obj) {
          return PostFormData("EndOfStageQuestion", obj).then((res) => {
            return res.data;
          });
        },
        async editEndOfStage({ commit }, obj) {
          return PutFormData("EndOfStageQuestion", obj).then((res) => {
            return res.data;
          });
        },
        // eslint-disable-next-line
        async restoreEndOfStageData({}, endofstagedataId) {
          try {
            const response = await makeApiCall(`EndOfStageQuestion/Restore/${endofstagedataId}`, `PUT`);
            if (response.status) {
              var res = response.data;
              if (res.status) {
                return res;
              }
            }
    
            return response.data;
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
        // eslint-disable-next-line
        async retireEndOfStageData({}, endofstagedataId) {
          try {
            const response = await makeApiCall(`EndOfStageQuestion/Retire/${endofstagedataId}`, `PUT`);
            if (response.status) {
              var res = response.data;
              if (res.status) {
                return res;
              }
            }
    
            return response.data;
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
    },
    mutations: {
      setEndOfStageList(state, payload) {
        state.endofstagedataList = payload;
      },
        setEndOfStageDataList(state, payload) {
            state.endofstagedataList = payload;
        },
        setRetiredEndOfStageDataList(state, payload) {
            state.retiredendofstageDataList = [...payload];
        },
        setEndOfStageData(state, payload) {
          state.endofstagedata = payload;
        },
        setEndOfStage(state, payload) {
          state.endofstage = payload;
        },
        setEndofStageIdGraphemeList(state, payload) {
          state.endofstageGraphemeListID = payload;
        },
        setEndofStageIdWordList(state, payload) {
          state.endofstageWordListID = payload;
        },
    }
}