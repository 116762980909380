/* eslint-disable no-unused-vars */
import { makeApiCall } from "../apiRequest";

export default {
  state: {
    weekNumberList: [],
    levelOfChallangeList: [],
  },
  getters: {
    getWeekNumberList: (state) => state.weekNumberList,
    getLevelOfChallange: (state) => state.levelOfChallangeList,
  },
  actions: {
    async getWeekNumberList({ commit }) {
      try {
        const response = await makeApiCall(`Shared/GetWeekNumberList`, `GET`);
        if (response.status && response.data?.data) {
          commit("setWeekNumberList", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getLevelOfChallange({ commit }) {
      try {
        const response = await makeApiCall(`Shared/GetLevelOfChallange`, `GET`);
        if (response.status && response.data?.data) {
          commit("setLevelOfChallangeList", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setWeekNumberList(state, payload) {
      state.weekNumberList = payload;
    },
    setLevelOfChallangeList(state, payload) {
      state.levelOfChallangeList = payload;
    },
  },
};
