/* eslint-disable no-unused-vars */
import { makeApiCall } from "../apiRequest";

export default {
  state: {
    staffList: [],
    retiredStaffList: [],
    staff: {},
    staffLinkedClassList: [],
    linkedClassesList: [],
    staffId: 0,
    LinekdStaffList: [],
    classesitemsPerPage: 0,
    staffitemsPerPage: 0,
    AllStaffList: [],
    staffListBySchoolId: [],
    schoolStaffList: [],
    ShareSatff: [],
  },
  getters: {
    getStaffs: (state) => state.staffList,
    getRetiredStaffs: (state) => state.retiredStaffList,
    getStaff: (state) => state.staff,
    getStaffLinkedClasses: (state) => state.staffLinkedClassList,
    GetLinkedClasses: (state) => state.linkedClassesList,
    GetLinkedStaffMembers: (state) => state.LinekdStaffList,
    getClassItemsperPage: (state) => state.classesitemsPerPage,
    getStaffItemsPerPage: (state) => state.staffitemsPerPage,
    getlinkedStaffItemsPerPage: (state) => state.staffitemsPerPage,
    GetAllStaffLists: (state) => state.AllStaffList,
    GetstaffListBySchoolIds: (state) => state.staffListBySchoolId,
    GetShareSatff: (state) => state.ShareSatff,
    getSchoolStaffList: (state) => state.schoolStaffList,
  },
  actions: {
    async getStaff({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Staff?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.staffs.length >= 0) {
                commit("setStaffList", res.data.staffs);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetLinkedClasse({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Staff/ViewLinkedClasses?pageNo=${obj.page}&pageSize=${obj.pageSize}&staffId=${obj.staffId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.classes.length >= 0) {
                commit("setLinkedClassList", res.data.classes);
                commit("setClassesItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getAllStaffBySchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Staff/GetAllStaffsBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data.data?.staffs?.length >= 0
        ) {
          commit("setStaffList", response.data.data.staffs);
          commit("setItemPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllStaffListBySchool({ commit, rootState }, schoolId) {
      try {
        const response = await makeApiCall(
          `Staff/GetStaffListBySchool?schoolId=${schoolId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data.data) {
          commit("setSchoolStaffList", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredStaff({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Staff/GetAllRetiredStaffs?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.staffs.length >= 0) {
                commit("setRetiredStaffList", res.data.staffs);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllRetiredStaffBySchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Staff/GetAllRetiredStaffsBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.staffs.length >= 0) {
                commit("setRetiredStaffList", res.data.staffs);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getStaffById({ commit }, staffId) {
      try {
        const response = await makeApiCall(`Staff/${staffId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setStaff", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetStaffListBySchoolId({ commit }, obj) {
      try {
        const response = await makeApiCall(
          `Staff/GetStaffListBySchool?schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data?.data) {
          commit("setstaffListBySchoolId", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    // async getStaffLinkedClasses({ commit }, obj) {
    //   try {
    //     const response = await makeApiCall(`Staff/ViewLinkedClasses?pageNo=${obj.page}&pageSize=${obj.pageSize}&staffId=${obj.staffId}`, `GET`);
    //     if (response.status) {
    //       var res = response.data;
    //       if (res.status) {
    //         if (res.data != null) {
    //           commit("setStaffLinkedClasses", res.data.classes);
    //           commit("setItemPerPage", res.data.totalItems);
    //         }
    //       }
    //     }

    //     return response.data;
    //   } catch (error) {
    //     console.error(error);
    //     throw error;
    //   }
    // },
    async GetLinkedSatffMember({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Class/ViewLinkedStaffMember?pageNo=${obj.page}&pageSize=${obj.pageSize}&classId=${obj.classId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.staffs.length >= 0) {
                commit("setLinkedStaffList", res.data.staffs);
                commit("setStaffItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async addStaff({ commit }, payload) {
      try {
        const response = await makeApiCall(`Staff`, `POST`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editStaff({ commit }, payload) {
      try {
        const response = await makeApiCall(`Staff`, `PUT`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetAllStaffList({ commit }) {
      try {
        const response = await makeApiCall(`Staff/GetStaffList`, `GET`);
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.length > 0) {
              commit("SetAllStaffList", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async restoreStaff({}, staffId) {
      try {
        const response = await makeApiCall(`Staff/Restore/${staffId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retireStaff({}, staffId) {
      try {
        const response = await makeApiCall(`Staff/Retire/${staffId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async ShareStaff({ commit }, staffId) {
      try {
        const response = await makeApiCall(
          `Staff/ShareCredentials?staffId=${staffId}`,
          `Get`
        );
        if (response.status && response.data?.status && response.data?.data) {
          commit("setShareSatff", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setStaffList(state, payload) {
      state.staffList = payload;
    },
    setSchoolStaffList(state, payload) {
      state.schoolStaffList = payload;
    },
    setRetiredStaffList(state, payload) {
      state.retiredStaffList = payload;
    },
    setStaff(state, payload) {
      state.staff = payload;
    },
    setStaffLinkedClasses(state, payload) {
      state.staffLinkedClassList = payload;
    },
    setLinkedClassList(state, payload) {
      state.linkedClassesList = payload;
    },
    setLinkedStaffList(state, payload) {
      state.LinekdStaffList = payload;
    },
    setClassesItemPerPage(state, payload) {
      state.classesitemsPerPage = payload;
    },
    setStaffItemPerPage(state, payload) {
      state.staffitemsPerPage = payload;
    },
    SetAllStaffList(state, payload) {
      state.AllStaffList = payload;
    },
    setstaffListBySchoolId(state, payload) {
      state.staffListBySchoolId = payload;
    },
    setShareSatff(state, payload) {
      state.ShareSatff = payload;
    },
  },
};
