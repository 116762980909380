let GlobalRoute = [
  {
    path: "/admin/dashboard",
    component: () => import("../views/Layout/GlobalLayout/DashboardLayout.vue"),
    redirect: "/admin/dashboard",
    children: [
      {
        path: "", // No path specified for the default child route
        component: () => import("../views/GlobalAdmin/Welcome.vue"),
      },
      // {
      //   path: "/admin/dashboard",
      //   component: () => import("../views/GlobalAdmin/Welcome.vue"),
      // },
      {
        path: "/school",
        component: () => import("../views/GlobalAdmin/School.vue"),
      },
      {
        path: "/staffmember",
        component: () => import("../views/GlobalAdmin/StaffMembers.vue"),
      },
      {
        path: "/classes",
        component: () => import("../views/GlobalAdmin/Classes.vue"),
      },
      {
        path: "/pupil",
        component: () => import("../views/GlobalAdmin/Pupil.vue"),
      },
      {
        path: "/parent",
        component: () => import("../views/GlobalAdmin/Parents.vue"),
      },

      {
        path: "/words",
        component: () => import("../views/GlobalAdmin/Words.vue"),
      },
      {
        path: "/graphemes",
        component: () => import("../views/GlobalAdmin/Graphemes.vue"),
      },
      {
        path: "/phoniclist",
        component: () => import("../views/GlobalAdmin/PhonicsList.vue"),
      },
      {
        path: "/phonicschemes",
        component: () => import("../views/GlobalAdmin/PhonicScheme.vue"),
      },
      {
        path: "/spellinglist",
        component: () => import("../views/GlobalAdmin/SpellingsList.vue"),
      },
      {
        path: "/stagesandsteps",
        component: () => import("../views/GlobalAdmin/StagesandSteps.vue"),
      },
      // {
      //   path: "/wordbucket",
      //   component: () => import("../views/GlobalAdmin/WordBucket.vue"),
      // },
      {
        path: "/sentences",
        component: () => import("../views/GlobalAdmin/Sentences.vue"),
      },
      {
        path: "/yeargroup",
        component: () => import("../views/GlobalAdmin/YearGroup.vue"),
      },
      {
        path: "/reports",
        component: () => import("../views/GlobalAdmin/Reports.vue"),
      },
      {
        path: "/schoolstaffroles",
        component: () => import("../views/GlobalAdmin/SchoolStaffRoles.vue"),
      },
      {
        path: "/globaluser",
        component: () => import("../views/GlobalAdmin/GlobalUsers.vue"),
      },
      {
        path: "/endofstage",
        component: () => import("../views/GlobalAdmin/EndOfStage.vue"),
      },
    ],
  },
];

export default GlobalRoute;
