import { makeApiCall } from "../apiRequest";

export default {
  state: {
    globalActiveUserList: [],
    globalRetiredUserList: [],
    globalUser: {},
  },
  getters: {
    getGlobalUsers: (state) => state.globalActiveUserList,
    getGlobalRetiredUsers: (state) => state.globalRetiredUserList,
    getGlobalUser: (state) => state.globalUser,
  },
  actions: {
    // eslint-disable-next-line
    async getGlobalUsers({ commit, rootState }, pagination) {
      try {
        const endpoint = `User?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`;
        const response = await makeApiCall(endpoint, 'GET');
        if (response.status) {
          const responseData = response.data;
          if (responseData.status) {
            const users = responseData.data ? responseData.data.users : [];
            commit("setGlobalActiveUserList", users);
            commit("setItemPerPage", responseData.data ? responseData.data.totalItems : 0);
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    // eslint-disable-next-line
    async getRetiredGlobalUsers({ commit, rootState }, pagination) {
      try {
        const endpoint = `User/GetAllRetiredUsers?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`;
        const response = await makeApiCall(endpoint, 'GET');
        if (response.status) {
          const responseData = response.data;
          if (responseData.status) {
            const users = responseData.data ? responseData.data.users : [];
            commit("setGlobalRetiredUserList", users);
            commit("setItemPerPage", responseData.data ? responseData.data.totalItems : 0);
          }
        }
      return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }, 
    async getGlobalUserById({ commit }, userId) {
      try {
        const endpoint = `User/${userId}`;
        const response = await makeApiCall(endpoint, 'GET');
        if (response.status) {
          const responseData = response.data;
    
          if (responseData.status && responseData.data) {
            commit("setGlobalUser", responseData.data);
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },  
    // eslint-disable-next-line
    async addGlobalUser({ }, payload) {
      try {
        const endpoint = `User`;
        const response = await makeApiCall(endpoint, 'POST', payload); 
        if (response.status && response.data.status) {
          return response.data;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async updateGlobalUser({ }, payload) {
      try {
        const response = await makeApiCall(`User`, `PUT`, payload);
        if (response.status && response.data.status) {
          return response.data;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retireUser({ }, userId) {
      try {
        const response = await makeApiCall(`User/Retire/${userId}`, `PUT`);
        if (response.status && response.data.status) {
          return response.data;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async restoreUser({ }, userId) {
      try {
        const response = await makeApiCall(`User/Restore/${userId}`, `PUT`);
        if (response.status && response.data.status) {
          return response.data;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setGlobalActiveUserList(state, payload) {
      state.globalActiveUserList = payload;
    },
    setGlobalRetiredUserList(state, payload) {
      state.globalRetiredUserList = payload;
    },
    setGlobalUser(state, payload) {
      state.globalUser = payload;
    },
  },
};
