import axios from "axios";

let headers = {};
//let token = localStorage.getItem("token");

async function makeApiCall(
  url, // FIRST PARAM  FOR END POINT
  method = "GET", // SECOND PARAM FOR METHOD TYPE BY DEFAULT GET
  data, //THIRD PARAM FOR DATA/BODY
  noAuth, //4TH IF WE HAVE TO DEAL WITH AUTH
  sendConfig,
  ...config
) {
  // let token = localStorage.getItem("token");
  // if (token !== null && !noAuth) {
  //   headers.Authorization = `Bearer ${token}`;
  // }

  // const response = await axios({
  //   method,
  //   data,
  //   url: `${process.env.VUE_APP_API_URL}${url}`,
  //   headers,
  //   ...config,
  // });
  // if (sendConfig) {
  //   return response;
  // }
  // return response;
  try {
    const token = localStorage.getItem("token");

    if (token !== null) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await axios({
      method,
      data,
      headers,
      url: `${process.env.VUE_APP_API_URL}${url}`,
      ...config,
    });

    if (sendConfig) {
      return response;
    }

    return response;
  } catch (error) {
    if (error?.response && error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
  }
}

export const PostFormData = async (url, payload) => {
  // var formHeaders = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "multipart/form-data",
  //   },
  // };

  // return await axios
  //   .post(`${process.env.VUE_APP_API_URL}${url}`, payload, formHeaders)
  //   .then((res) => {
  //     return res;
  //   });
  try {
    const token = localStorage.getItem("token");

    if (token !== null) {
      var formHeaders = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
    }

    return await axios
      .post(`${process.env.VUE_APP_API_URL}${url}`, payload, formHeaders)
      .then((res) => {
        return res;
      });
  } catch (error) {
    if (error?.response && error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
  }
};
export const PutFormData = async (url, payload) => {
  // var formHeaders = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "multipart/form-data",
  //   },
  // };

  // return await axios
  //   .put(`${process.env.VUE_APP_API_URL}${url}`, payload, formHeaders)
  //   .then((res) => {
  //     return res;
  //   });
  try {
    const token = localStorage.getItem("token");

    if (token !== null) {
      var formHeaders = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
    }

    return await axios
      .put(`${process.env.VUE_APP_API_URL}${url}`, payload, formHeaders)
      .then((res) => {
        return res;
      });
  } catch (error) {
    if (error?.response && error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
  }
};
export const Restore = async (url) => {
  // var Header = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   },
  // };
  // return await axios
  //   .put(`${process.env.VUE_APP_API_URL}${url}`, null, Header)
  //   .then((res) => {
  //     return res;
  //   });
  try {
    const token = localStorage.getItem("token");

    if (token !== null) {
      var Header = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
    }

    return await axios
      .put(`${process.env.VUE_APP_API_URL}${url}`, null, Header)
      .then((res) => {
        return res;
      });
  } catch (error) {
    if (error?.response && error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
  }
};
export const Retire = async (url) => {
  // var Header = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   },
  // };
  // return await axios
  //   .put(`${process.env.VUE_APP_API_URL}${url}`, null, Header)
  //   .then((res) => {
  //     return res;
  //   });
  try {
    const token = localStorage.getItem("token");

    if (token !== null) {
      var Header = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
    }

    return await axios
      .put(`${process.env.VUE_APP_API_URL}${url}`, null, Header)
      .then((res) => {
        return res;
      });
  } catch (error) {
    if (error?.response && error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
  }
};
export { makeApiCall };
