/* eslint-disable no-unused-vars */
import {
  makeApiCall,
  PostFormData,
  PutFormData,
  Restore,
  Retire,
} from "../apiRequest";

export default {
  state: {
    stagesandstepdata: {},
    stagesandstepdataList: [],
    retiredStagesandStepDataList: [],
    itemPerPage: 0,
    ViewStepsList: [],
    ViewStepsItemsPerPage: 0,
    ViewWordsItemsPerPage: 0,
    ViewWordsList: [],
    ViewGraphemesItemsPerPage: 0,
    ViewGraphemesList: [],
    ViewGamesItemsPerPage: 0,
    ViewGamesList: [],
    ViewStageList: [],
  },
  getters: {
    getStagesandStepLists: (state) => state.stagesandstepdataList,
    getStagesandStepData: (state) => state.stagesandstepdata,
    getRetiredStagesandStepLists: (state) => state.retiredStagesandStepDataList,
    getItemsPerPage: (state) => state.itemPerPage,
    GetViewStepsItemsPerPage: (state) => state.ViewStepsItemsPerPage,
    GetViewStepsList: (state) => state.ViewStepsList,
    GetViewWordsItemsPerPage: (state) => state.ViewWordsItemsPerPage,
    GetViewWordsList: (state) => state.ViewWordsList,
    GetViewGraphemesItemsPerPage: (state) => state.ViewGraphemesItemsPerPage,
    GetViewGraphemesList: (state) => state.ViewGraphemesList,
    GetViewGamesItemsPerPage: (state) => state.ViewGamesItemsPerPage,
    GetViewGamesList: (state) => state.ViewGamesList,
    GetStagesList: (state) => state.ViewStageList,
  },
  actions: {
    async getStagesandStepData({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `StageStep?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.stageSteps.length >= 0) {
                commit("setStagesandStepDataList", res.data.stageSteps);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async ViewSteps({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `StageStep/ViewSteps?pageNo=${obj.page}&pageSize=${obj.pageSize}&stageId=${obj.stageId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data.data?.stageSteps?.length >= 0
        ) {
          commit("setViewStepsList", response.data.data.stageSteps);
          commit("setViewStepsItemsPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async ViewWords({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `StageStep/ViewWords?pageNo=${obj.page}&pageSize=${obj.pageSize}&stageId=${obj.stageId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data.data?.stageWords?.length >= 0
        ) {
          commit("setViewWordsList", response.data.data.stageWords);
          commit("setViewWordsItemsPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async ViewGraphemes({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `StageStep/ViewGraphemes?pageNo=${obj.page}&pageSize=${obj.pageSize}&stageId=${obj.stageId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data.data?.stageGramphemes?.length >= 0
        ) {
          commit("setViewGraphemesList", response.data.data.stageGramphemes);
          commit("setViewGraphemesItemsPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async ViewGames({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `StageStep/ViewGames?pageNo=${obj.page}&pageSize=${obj.pageSize}&stageId=${obj.stageId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data.data?.stageGames?.length >= 0
        ) {
          commit("setViewGamesList", response.data.data.stageGames);
          commit("setViewGamesItemsPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredStagesandStepData({ commit }, pagination) {
      try {
        const response = await makeApiCall(
          `StageStep/GetAllRetiredStageSteps?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.stageSteps?.length >= 0) {
          commit("setRetiredStagesandStepDataList", res.data.stageSteps);
          commit("setItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getStagesandStepDataById({ commit }, stagesandstepdataId) {
      try {
        const response = await makeApiCall(
          `StageStep/${stagesandstepdataId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setStagesandStepData", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getStagesList({ commit }) {
      try {
        const response = await makeApiCall(
          `StageStep/GetStagesList`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setStagesList", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async addStagesandStepData({ commit }, payload) {
      try {
        const response = await makeApiCall(`StageStep`, `POST`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editStagesandStepData({ commit }, payload) {
      try {
        const response = await makeApiCall(`StageStep`, `PUT`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async restoreStagesandStep({}, stagesandstepId) {
      try {
        const response = await makeApiCall(
          `StageStep/Restore/${stagesandstepId}`,
          `PUT`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retireStagesandStep({}, stagesandstepId) {
      try {
        const response = await makeApiCall(
          `StageStep/Retire/${stagesandstepId}`,
          `PUT`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setStagesandStepDataList(state, payload) {
      state.stagesandstepdataList = payload;
    },
    setStagesandStepData(state, payload) {
      state.stagesandstepdata = payload;
    },
    setRetiredStagesandStepDataList(state, payload) {
      state.retiredStagesandStepDataList = [...payload];
    },
    setItemPerPage(state, payload) {
      state.itemPerPage = payload;
    },
    setViewStepsItemsPerPage(state, payload) {
      state.ViewStepsItemsPerPage = payload;
    },
    setViewStepsList(state, payload) {
      state.ViewStepsList = payload;
    },
    setViewWordsItemsPerPage(state, payload) {
      state.ViewWordsItemsPerPage = payload;
    },
    setViewWordsList(state, payload) {
      state.ViewWordsList = payload;
    },
    setViewGraphemesItemsPerPage(state, payload) {
      state.ViewGraphemesItemsPerPage = payload;
    },
    setViewGraphemesList(state, payload) {
      state.ViewGraphemesList = payload;
    },
    setViewGamesItemsPerPage(state, payload) {
      state.ViewGamesItemsPerPage = payload;
    },
    setViewGamesList(state, payload) {
      state.ViewGamesList = payload;
    },
    setStagesList(state, payload) {
      state.ViewStageList = payload;
    },
  },
};
