let PagesRoute = [
    {
      path: "/page/PrivacyPolicy",
      component: () => import("../views/Layout/PagesLayout/pagesLayout.vue"),
      redirect: "/page/PrivacyPolicy",
      children: [
        {
          path: "/page/PrivacyPolicy",
          component: () => import("../views/PrivacyPolicyPages/privacyPolicy.vue")
        },
        {
          path: "/page/termsOfservice",
          component: () => import("../views/PrivacyPolicyPages/termsOfservice.vue")
        },
        {
          path: "/page/parent-terms-of-service",
          component: () => import("../views/PrivacyPolicyPages/TOSparent.vue")
        },
        {
          path: "/page/school-terms-of-service",
          component: () => import("../views/PrivacyPolicyPages/TOSSchool.vue")
        },
    
    
      ],
    },
  ];
  
  export default PagesRoute;
  