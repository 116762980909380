/* eslint-disable no-unused-vars */
import {
  makeApiCall,
  PostFormData,
  PutFormData,
  Restore,
  Retire,
} from "../apiRequest";

export default {
  state: {
    phonicdataList: [],
    allPhonicListByStaff: [],
    phonicListByParent: [],
    phonicListByTeacher: [],
    builtInPhonicDataList: [],
    builtInPhonicDataListSchool: [],
    builtInPhonicDataListTeacher: [],
    itemPerPage: 0,
    retiredPhonicDataList: [],
    retiredPhonicListByParent: [],
    retiredPhonicListByStaff: [],
    linkedPupilByParent: [],
    phonicdata: {},
    schoolPhonicList: [],
    phoniclistBySchool: [],
    schoolretiredPhonicList: [],
  },
  getters: {
    getPhonicLists: (state) => state.phonicdataList,
    getPhonicListByParent: (state) => state.phonicListByParent,
    getPhonicListByTeacher: (state) => state.phonicListByTeacher,
    GetPhonicListBySchoolFull: (state) => state.phoniclistBySchool,
    getBuiltInPhonicLists: (state) => state.builtInPhonicDataList,
    getBuiltInPhonicListsSchool: (state) => state.builtInPhonicDataListSchool,
    getBuiltInPhonicListsTeacher: (state) => state.builtInPhonicDataListTeacher,
    getItemsPerPage: (state) => state.itemPerPage,
    getRetiredPhonicLists: (state) => state.retiredPhonicDataList,
    getRetiredPhonicListByParent: (state) => state.retiredPhonicListByParent,
    getRetiredPhonicListByStaff: (state) => state.retiredPhonicListByStaff,
    getPhonicData: (state) => state.phonicdata,
    getLinkedPupilsByParent: (state) => state.linkedPupilByParent,
    getPhonicsSchool: (state) => state.schoolPhonicList,
    getRetiredPhonicsSchool: (state) => state.schoolretiredPhonicList,
    getAllPhonicListByStaff: (state) => state.allPhonicListByStaff,
  },
  actions: {
    async getAllPhonicListByStaff({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllPhonicListByStaff?creatorStaffId=${obj}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.length >= 0) {
                commit("setAllPhonicListByStaff", res.data);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async assignPhonicListToPupil({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `PhonicsList/AssignPhonicsListToPupil`,
          `PUT`,
          payload
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async assignPhonicListToClass({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `PhonicsList/AssignPhonicsListToClass`,
          `PUT`,
          payload
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async assignPhonicListToGroup({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `PhonicsList/AssignPhonicsListToGroup`,
          `PUT`,
          payload
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetPhonicListBySchoolFulls({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllPhonicListBySchool?&schoolId=${payload}`,
          `GET`
        );
        const res = response.data;
        if (res.data) {
          commit("setPhonicListBySchool", res.data);
        
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getBuiltInPhonicList({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllBuiltInPhonicsLists?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.phonicsLists.length >= 0) {
                commit("setBuiltInPhonicDataList", res.data.phonicsLists);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getBuiltInPhonicListSchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllBuiltInPhonicsLists?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.phonicsLists.length >= 0) {
                commit("setBuiltInPhonicDataListSchool", res.data.phonicsLists);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getBuiltInPhonicListTeacher({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllBuiltInPhonicsLists?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.phonicsLists.length >= 0) {
                commit("setBuiltInPhonicDataListTeacher", res.data.phonicsLists);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async copyPhonicData({ commit }, phonicdataId) {
      try {
        const response = await makeApiCall(
          `PhonicsList/CopyPhonicsList/${phonicdataId}`,
          `PUT`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPhonicListByTeacher({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllPhonicsListsByStaff?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&creatorStaffId=${obj.creatorStaffId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.phonicsLists.length >= 0) {
                commit("setPhonicListByTeacher", res.data.phonicsLists);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPhonicListByParent({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllPhonicsListsByUser?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.phonicsLists.length >= 0) {
                commit("setPhonicListByParent", res.data.phonicsLists);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPhonicData({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicsList?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.phonicsLists.length >= 0) {
                commit("setPhonicDataList", res.data.phonicsLists);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredPhonicData({ commit }, pagination) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllRetiredPhonicsLists?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.phonicsLists?.length >= 0) {
          commit("setRetiredPhonicDataList", res.data.phonicsLists);
          commit("setItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredPhonicListByStaff({ commit }, pagination) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllRetiredPhonicsListsByStaff?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}&creatorStaffId=${pagination.creatorStaffId}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.phonicsLists?.length >= 0) {
          commit("setRetiredPhonicListByStaff", res.data.phonicsLists);
          commit("setItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredPhonicListByParent({ commit }, pagination) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllRetiredPhonicsListsByUser?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}&userId=${pagination.userId}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.phonicsLists?.length >= 0) {
          commit("setRetiredPhonicListByParent", res.data.phonicsLists);
          commit("setItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPhonicSchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllPhonicsListsBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.phonicsLists.length >= 0) {
                commit("setPhonicListSchool", response.data.data.phonicsLists);
                commit("setItemPerPage", response.data.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredPhonicSchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicsList/GetAllRetiredPhonicsListsBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.phonicsLists.length >= 0) {
                commit("setRetiredPhonicListSchool", res.data.phonicsLists);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPhonicDataById({ commit }, phonicdataId) {
      try {
        const response = await makeApiCall(
          `PhonicsList/${phonicdataId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setPhonicData", res.data);
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addPhonicData({ commit }, payload) {
      try {
        const response = await makeApiCall(`PhonicsList`, `POST`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editPhonicData({ commit }, payload) {
      try {
        const response = await makeApiCall(`PhonicsList`, `PUT`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async restorePhonicData({}, phonicdataId) {
      try {
        const response = await makeApiCall(
          `PhonicsList/Restore/${phonicdataId}`,
          `PUT`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retirePhonicData({}, phonicdataId) {
      try {
        const response = await makeApiCall(
          `PhonicsList/Retire/${phonicdataId}`,
          `PUT`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getLinkedPupilsByParent({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicsList/ViewLinkedPupils?pageNo=${obj.page}&pageSize=${obj.pageSize}&phonicsListId=${obj.phoniclistId}&userId=${obj.userId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.pupils.length >= 0) {
                commit("setLinkedPupilListByParent", res.data.pupils);
                commit("setPupilsItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setAllPhonicListByStaff(state, payload) {
      state.allPhonicListByStaff = payload;
    },
    setPhonicDataList(state, payload) {
      state.phonicdataList = payload;
    },
    setPhonicListBySchool(state, payload) {
      state.phoniclistBySchool = payload;
    },
    setLinkedPupilListByParent(state, payload) {
      state.linkedPupilByParent = payload;
    },
    setPhonicListByParent(state, payload) {
      state.phonicListByParent = payload;
    },
    setPhonicListByTeacher(state, payload) {
      state.phonicListByTeacher = payload;
    },
    setBuiltInPhonicDataList(state, payload) {
      state.builtInPhonicDataList = payload;
    },
    setBuiltInPhonicDataListSchool(state, payload) {
      state.builtInPhonicDataListSchool = payload;
    },
    setBuiltInPhonicDataListTeacher(state, payload) {
      state.builtInPhonicDataListTeacher = payload;
    },
    setRetiredPhonicDataList(state, payload) {
      state.retiredPhonicDataList = [...payload];
    },
    setRetiredPhonicListByParent(state, payload) {
      state.retiredPhonicListByParent = [...payload];
    },
    setRetiredPhonicListByStaff(state, payload) {
      state.retiredPhonicListByStaff = [...payload];
    },
    setPhonicData(state, payload) {
      state.phonicdata = payload;
    },
    setItemPerPage(state, payload) {
      state.itemPerPage = payload;
    },
    setPhonicListSchool(state, payload) {
      state.schoolPhonicList = payload;
    },
    setRetiredPhonicListSchool(state, payload) {
      state.schoolretiredPhonicList = payload;
    },
  },
};
