/* eslint-disable no-unused-vars */
import { makeApiCall } from "../apiRequest";

export default {
  state: {
    allSpellingListByStaff: [],
    spellingList: [],
    spellingListSchool: [],
    spellingListStaff: [],
    spellingListByParent: [],
    retireitemPerPage: 0,
    itemPerPage: 0,
    retirespellinglist: [],
    retirespellinglistByUser: [],
    retirespellinglistBySchool: [],
    retirespellinglistByStaff: [],
    builtInSpellingList: [],
    linkedPupilByParent: [],
    singleSpellingList: {},
    SchoolsUsingSpellingList: {},
    builtInSpellingDataList: [],
    builtInSpellingDataListSchool: [],
    builtInSpellingDataListTeacher: [],
    spellinglistByTeacher: [],
    spellinglistBySchool: [],
    SchoolsUsingSpellingItemPerPage: 0,
    StaticsOfSpellingList:[],
  },
  getters: {
    GetSpellingLists: (state) => state.spellingList,
    GetSpellingListByTeacher: (state) => state.spellinglistByTeacher,
    GetSpellingListBySchoolFull: (state) => state.spellinglistBySchool,
    GetAllSpellingListsByStaff: (state) => state.spellingListStaff,
    GetSpellingListsSchool: (state) => state.spellingListSchool,
    GetSpellingListByParent: (state) => state.spellingListByParent,
    GetAllBuildInSpellingList: (state) => state.builtInSpellingList,
    getItemsPerPage: (state) => state.itemPerPage,
    getSpellingListLinkedPupilsByParent: (state) => state.linkedPupilByParent,
    GetRetireSpellingLists: (state) => state.retirespellinglist,
    GetSpellingList: (state) => state.singleSpellingList,
    GetViewSchoolsUsingSpellingLists: (state) => state.SchoolsUsingSpellingList,
    GetSchoolsUsingSpellingItemPerPage: (state) =>
      state.SchoolsUsingSpellingItemPerPage,
    GetRetireSpellingListByUsers: (state) => state.retirespellinglistByUser,
    GetRetireSpellingListBySchools: (state) => state.retirespellinglistBySchool,
    GetRetireSpellingListByStaff: (state) => state.retirespellinglistByStaff,
    getBuiltInSpellingLists: (state) => state.builtInSpellingDataList,
    getBuiltInSpellingListsSchool: (state) =>
      state.builtInSpellingDataListSchool,
    getBuiltInSpellingListsTeacher: (state) =>
      state.builtInSpellingDataListTeacher,
    GetStaticsOfSpellingLists: (state) => state.StaticsOfSpellingList,
  },
  actions: {
    // eslint-disable-next-line
    async assignSpellingListToPupil({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/AssignSpellingListToPupil`,
          `PUT`,
          payload
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async assignSpellingListToClass({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/AssignSpellingListToClass`,
          `PUT`,
          payload
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async assignSpellingListToGroup({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/AssignSpellingListsToGroup`,
          `PUT`,
          payload
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async getSpellingListLinkedPupilsByParent({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `SpellingList/ViewLinkedPupils?pageNo=${obj.page}&pageSize=${obj.pageSize}&spellingListsId=${obj.phoniclistId}&userId=${obj.userId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.pupils.length >= 0) {
                commit("setLinkedPupilListByParent", res.data.pupils);
                commit("setPupilsItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetSpellingListByParent({ commit, rootState }, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllSpellingListsByUser?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}&userId=${payload.userId}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.spellingLists?.length >= 0) {
          commit("setspellingListByParent", res.data.spellingLists);
          commit("setItemPerPage", res.data.totalItems);
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  
    // eslint-disable-next-line
    async GetSpellingList({ commit, rootState }, pagination) {
      try {
        const response = await makeApiCall(
          `SpellingList?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.spellingLists?.length > 0) {
          commit("setspellingList", res.data.spellingLists);
          commit("setItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetStaticsOfSpellingList({ commit, rootState }, payload) {
      try {
        debugger;
        const response = await makeApiCall(
          `SpellingList/GetStaticsOfSpellingList?spellingListId=${payload.spellingListId}&schoolId=${payload.schoolId}&startDate=${payload.startDate}&endDate=${payload.endDate}`,
          `GET`
        );
        const res = response.data;
        if (res.data) {
          commit("setStaticsOfSpellingList", res.data);
        
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
       
    },

    async getBuiltInSpellingList({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllBuiltIndSpellingLists?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.spellingLists.length >= 0) {
                commit("setBuiltInSpellingDataList", res.data.spellingLists);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getBuiltInSpellingListTeacher({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllBuiltIndSpellingLists?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.spellingLists.length >= 0) {
                commit(
                  "setBuiltInSpellingDataListTeacher",
                  res.data.spellingLists
                );
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getBuiltInSpellingListSchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllBuiltIndSpellingLists?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.spellingLists.length >= 0) {
                commit(
                  "setBuiltInSpellingDataListSchool",
                  res.data.spellingLists
                );
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async getRetiredSpellingListByUser({ commit, rootState }, pagination) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllRetiredSpellingListsByUser?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}&userId=${pagination.userId}`,
          `GET`
        );
        const res = response.data;

        if (res?.status && res.data?.spellingLists?.length >= 0) {
          commit("setRetireSpellingListByUser", res.data.spellingLists);
          commit("setItemPerPage", res.data.totalItems);
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetRetireSpellingListByStaff({ commit, rootState }, pagination) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllRetiredSpellingListsByStaff?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}&creatorStaffId=${pagination.StaffId}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.spellingLists?.length >= 0) {
          commit("setRetireSpellingListByStaff", res.data.spellingLists);
          commit("setItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async getRetiredSpellingListBySchool({ commit, rootState }, pagination) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllRetiredSpellingListsBySchool?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}&schoolId=${pagination.schoolId}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.spellingLists?.length >= 0) {
          commit("setRetireSpellingListBySchool", res.data.spellingLists);
          commit("setItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async getRetiredSpellingList({ commit, rootState }, pagination) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllRetiredSpellingLists?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.spellingLists?.length >= 0) {
          commit("setRetireSpellingList", res.data.spellingLists);
          commit("setItemPerPage", res.data.totalItems);
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetSpellingListByUser({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllSpellingListsByUser?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}&userId=${payload.userId}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.spellingLists?.length > 0) {
          commit("setspellingList", res.data.spellingLists);
          commit("setItemPerPage", res.data.totalItems);
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetSpellingListByTeacher({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllSpellingListByStaff?&creatorStaffId=${payload}`,
          `GET`
        );
        const res = response.data;
        if (res.data) {
          commit("setSpellingListByTeacher", res.data);
        
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetSpellingListBySchoolFulls({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetSpellingList?&schoolId=${payload}`,
          `GET`
        );
        const res = response.data;
        if (res.data) {
          commit("setSpellingListBySchool", res.data);
        
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetAllSpellingListsByStaff({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllSpellingListsByStaff?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}&creatorStaffId=${payload.StaffId}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.spellingLists?.length > 0) {
          commit("setspellingListStaff", res.data.spellingLists);
          commit("setItemPerPage", res.data.totalItems);
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetSpellingListBySchool({ commit, rootState }, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllSpellingListsBySchool?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}&schoolId=${payload.schoolId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.spellingLists.length >= 0) {
                commit("setspellingListSchool", response.data.data.spellingLists);
                commit("setItemPerPage", response.data.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async getRetiredSpellingList({ commit, rootState }, pagination) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllRetiredSpellingLists?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.spellingLists?.length >= 0) {
          commit("setRetireSpellingList", res.data.spellingLists);
          commit("setItemPerPage", res.data.totalItems);
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetRetireSpellingListByUser({ commit, rootState }, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/GetAllRetiredSpellingListsByUser?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}&userId=${payload.userId}`,
          `GET`
        );
        const res = response.data;
        if (res?.status && res.data?.spellingLists?.length >= 0) {
          commit("setRetireSpellingListByUser", res.data.spellingLists);
          commit("setItemPerPage", res.data.totalItems);
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async copySpellingData({ commit }, spellingId) {
      try {
        const response = await makeApiCall(
          `SpellingList/CopySpelingList/${spellingId}`,
          `PUT`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async AddSpellingList({ commit }, payload) {
      try {
        const response = await makeApiCall(`SpellingList`, "POST", payload);
        if (response.status) var res = response.data;
        if (res.status) {
          return res;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async UpdateSpellingList({}, payload) {
      try {
        const response = await makeApiCall(`SpellingList`, `PUT`, payload);
        if (response.status) var res = response.data;
        if (res.status) {
          return res;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async GetViewSchoolsUsingSpellingList({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `SpellingList/ViewSchoolsUsingSpellingList?pageNo=${obj.page}&pageSize=${obj.pageSize}&spellingListId=${obj.spellingListId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data.data?.schools?.length >= 0
        ) {
          commit("setViewSchoolsUsingSpellingList", response.data.data.schools);
          commit(
            "setSchoolsUsingSpellingItemPerPage",
            response.data.data.totalItems
          );
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retireSpelling({}, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/Retire/${payload}`,
          `PUT`
        );
        if (response.status) var res = response.data;
        if (res.status) {
          return res;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async restoreSpelling({}, payload) {
      try {
        const response = await makeApiCall(
          `SpellingList/Restore/${payload}`,
          `PUT`
        );
        if (response.status) var res = response.data;
        if (res.status) {
          return res;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getSpellingListById({ commit }, id) {
      try {
        const response = await makeApiCall(`SpellingList/${id}`, `GET`);
        if (response.status && response.data?.data) {
          commit("setSingleSpellingList", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },

  mutations: {
    setLinkedPupilListByParent(state, payload) {
      state.linkedPupilByParent = payload;
    },
    setSpellingListByTeacher(state, payload) {
      state.spellinglistByTeacher = payload;
    },
    setSpellingListBySchool(state, payload) {
      state.spellinglistBySchool = payload;
    },
    setAllSpellingListByStaff(state, payload) {
      state.allSpellingListByStaff = payload;
    },
    setspellingListByParent(state, payload) {
      state.spellingListByParent = payload;
    },
    setspellingList(state, payload) {
      state.spellingList = payload;
    },
    setBuilInSpellingList(state, payload) {
      state.builtInSpellingList = payload;
    },
    setspellingListSchool(state, payload) {
      state.spellingListSchool = payload;
    },
    setspellingListStaff(state, payload) {
      state.spellingListStaff = payload;
    },
    setItemPerPage(state, payload) {
      state.itemPerPage = payload;
    },
    setRetireSpellingList(state, payload) {
      state.retirespellinglist = payload;
    },
    setRetireSpellingListByUser(state, payload) {
      state.retirespellinglistByUser = payload;
    },
    setRetireSpellingListBySchool(state, payload) {
      state.retirespellinglistBySchool = payload;
    },
    setRetireSpellingListByStaff(state, payload) {
      state.retirespellinglistByStaff = payload;
    },

    setSingleSpellingList(state, payload) {
      state.singleSpellingList = payload;
    },
    setViewSchoolsUsingSpellingList(state, payload) {
      state.SchoolsUsingSpellingList = payload;
    },
    setSchoolsUsingSpellingItemPerPage(state, payload) {
      state.SchoolsUsingSpellingItemPerPage = payload;
    },
    setBuiltInSpellingDataList(state, payload) {
      state.builtInSpellingDataList = payload;
    },
    setBuiltInSpellingDataListSchool(state, payload) {
      state.builtInSpellingDataListSchool = payload;
    },
    setBuiltInSpellingDataListTeacher(state, payload) {
      state.builtInSpellingDataListTeacher = payload;
    },
    setStaticsOfSpellingList(state, payload)
    {
      state.StaticsOfSpellingList = payload;
    }
  },
};
