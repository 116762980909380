let TeacherRoute = [
  {
    path: "/staff/dashboard",
    component: () =>
      import("../views/Layout/TeacherLayout/DashboardLayout.vue"),
    redirect: "/staff/dashboard",
    children: [
      {
        path: "/staff/dashboard",
        component: () => import("../views/GlobalAdmin/Welcome.vue"),
      },

      {
        path: "/teacher/classes",
        component: () => import("../views/TeacherAdmin/Classes.vue"),
      },
      {
        path: "/teacher/pupil",
        component: () => import("../views/TeacherAdmin/Pupil.vue"),
      },
      {
        path: "/teacher/groupandsetting",
        component: () => import("../views/TeacherAdmin/GroupAndSetting.vue"),
      },
      {
        path: "/teacher/builtinspellinglist",
        component: () =>
          import("../views/TeacherAdmin/BuiltInSpellingList.vue"),
      },
      {
        path: "/teacher/spellinglist",
        component: () => import("../views/TeacherAdmin/SpellingList.vue"),
      },
      {
        path: "/teacher/customword",
        component: () => import("../views/TeacherAdmin/CustomWord.vue"),
      },
      {
        path: "/teacher/builtinphonic",
        component: () => import("../views/TeacherAdmin/PhonicList.vue"),
      },
      {
        path: "/teacher/customphonic",
        component: () => import("../views/TeacherAdmin/CustomPhonicList.vue"),
      },
      {
        path: "/teacher/report",
        component: () => import("../views/TeacherAdmin/Reports.vue"),
      },
    ],
  },
];

export default TeacherRoute;
