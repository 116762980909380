let ParentRoute = [
  {
    path: "/parent/dashboard",
    component: () => import("../views/Layout/ParentLayout/DashboardLayout.vue"),
    redirect: "/parent/dashboard",
    children: [
      {
        path: "/parent/dashboard",
        component: () => import("../views/GlobalAdmin/HomeScreen.vue"),
      },
      {
        path: "/parent/accountdetail",
        component: () => import("../views/ParentAdmin/AccountDetail.vue"),
      },
      {
        path: "/parent/children",
        component: () => import("../views/ParentAdmin/MyChildren.vue"),
      },
      {
        path: "/parent/subscription",
        component: () => import("../views/ParentAdmin/Subscription.vue"),
      },
      {
        path: "/parent/customspellinglist",
        component: () => import("../views/ParentAdmin/SpellingsList.vue"),
      },
      {
        path: "/parent/builtinspelling",
        component: () => import("../views/ParentAdmin/BuiltInSpellingList.vue"),
      },
      {
        path: "/parent/customword",
        component: () => import("../views/ParentAdmin/CustomWord.vue"),
      },
      {
        path: "/parent/builtinphonic",
        component: () => import("../views/ParentAdmin/PhonicList.vue"),
      },
      {
        path: "/parent/customphonic",
        component: () => import("../views/ParentAdmin/CustomPhonicList.vue"),
      },
      {
        path: "/parent/report",
        component: () => import("../views/ParentAdmin/Reports.vue"),
      },
    ],
  },
];

export default ParentRoute;
