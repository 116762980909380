/* eslint-disable no-unused-vars */
import axios from "axios";
import { makeApiCall } from "../apiRequest";

export default {
  state: {
    accountUserId: null,
  },
  getters: {
    getUserID: (state) => state.accountUserId,
  },
  actions: {
    async forgetPassword({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `User/ForgetPassword`,
          `POST`,
          payload
        );
        if (response.status) {
          var res = response.data;
          if (res) {
            commit("setUserID", res.data.userId);
            return res;
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async resetPassword({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `User/ResetPassword`,
          `POST`,
          payload
        );

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setUserID(state, payload) {
      state.accountUserId = payload;
    },
  },
};
