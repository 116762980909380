/* eslint-disable no-unused-vars */
import {
    makeApiCall,
    PostFormData,
    PutFormData,
    Restore,
    Retire,
  } from "../apiRequest";
  
  export default {
    state: {
      spaceRaceList: [],
      retiredSpaceRaceList: [],
      spaceRaceItemPerPage:0,
      retiredSpaceRaceItemPerPage:0,
      spaceRace: {},
      GameIdList:[],
      racetype:[],
      GamespaceIdList:[],
    },
    getters: {
      getSpaceRaceList: (state) => state.spaceRaceList,
      getRetiredSpaceRaceList: (state) => state.retiredSpaceRaceList,
      getSchool: (state) => state.school,
      getSpaceRaceItemPerPage: (state) => state.spaceRaceItemPerPage,
      getRetiredSpaceRaceItemPerPage: (state) => state.retiredSpaceRaceItemPerPage,
      getSpaceRace: (state) => state.spaceRace,
      getallGamesLists : (state)=> state.GameIdList,
      getRaceTypeLists: (state) =>state.racetype,
      SpaceRaceGameByIds: (state)=> state.GamespaceIdList,
    },
    actions: {
        async addSpaceRace({ commit }, payload) {
            try {
              const response = await makeApiCall(`SpaceRace`, `POST`, payload);
              if (response.status) {
                var res = response.data;
                if (res.status) {
                  return res;
                }
              }
      
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          async fetchSpaceRace({ commit, rootState }, obj) {
            try {
              const response = await makeApiCall(
                `SpaceRace?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
                `GET`
              );
              if (response.status) {
                if (response.status && response.data?.status && response.data.data.spaceRace?.length >= 0) {
                    commit("setSpaceRaceList", response.data.data.spaceRace);
                    commit("setSpaceRaceItemPerPage", response.data.data.totalItems);     
                }
              }
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          async retireSpaceRace({ commit, rootState }, spaceRaceId) {
            try {
              const response = await makeApiCall(`SpaceRace/Retire/${spaceRaceId}`, `PUT`);
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          async restoreSpaceRace({ commit, rootState }, spaceRaceId) {
            try {
              const response = await makeApiCall(`SpaceRace/Restore/${spaceRaceId}`, `PUT`);
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          async getAllRetiredSpaceRaceBySchool({ commit, rootState }, obj) {
            try {
              const response = await makeApiCall(`SpaceRace/GetAllRetiredSpaceRaces?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`, `GET`);
              if(response.status && response.data?.status && response.data.data?.spaceRace?.length >= 0){
                commit("setRetiredSpaceRaceList", response.data.data.spaceRace);
                commit("setSpaceRaceItemPerPage", response.data.data.totalItems);
              
            }
            return response.data;
          }
            catch (error) {
              console.error(error);
              throw error;
            }
          },
          async editSpaceRace({ commit }, payload) {
            try {
              const response = await makeApiCall(`SpaceRace`, `PUT`, payload);
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          async getSpaceRaceById({ commit }, spaceRaceId) {
            try {
              const response = await makeApiCall(`SpaceRace/${spaceRaceId}`, `GET`);
              if (response.status && response.data?.status && response.data.data) {
                  commit("setSpaceRace", response.data.data);
              }
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          async GetAllGamesList({ commit }) {
            try {
              const response = await makeApiCall(
                `Game/GetGamesWithTypeList`,
                `GET`
              );
              if (response.status) var res = response.data;
              if (res.status) {
                if (res.data != null) {
                  if (res.data.length > 0) {
                    commit("setGameIdList", res.data);
                  }
                }
              }
              return response.data;
            }
            catch (error) {
              console.error(error);
              throw error;
            }
          },
          async GetRaceTypeList({ commit }) {
            try {
              const response = await makeApiCall(
                `SpaceRace/GetRaceTypeList`,
                `GET`
              );
              if (response.status) var res = response.data;
              if (res.status) {
                if (res.data != null) {
                  if (res.data.length > 0) {
                    commit("setRaceTypeList", res.data);
                  }
                }
              }
              return response.data;
            }
            catch (error) {
              console.error(error);
              throw error;
            }
          },
          async SpaceRaceGameById({ commit }, raceTypeIds) {
            try {
              const response = await makeApiCall(
                `SpaceRace/GetGamesByRaceType`,
                `POST`,
                { raceTypeId: raceTypeIds }
              );
              if (response.status && response.data?.status && response.data.data) {
                commit("setSpaceRaceGame", response.data.data);
              }
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          
          
    },
    mutations: {
      setGameIdList(state, payload)
      {
        state.GameIdList = payload;
      },
      setSpaceRaceGame(state, payload) {
        state.GamespaceIdList = payload;
      },
      setSpaceRaceList(state, payload) {
        state.spaceRaceList = payload;
      },
      setRetiredSpaceRaceList(state, payload) {
        state.retiredSpaceRaceList = payload;
      },
      setSpaceRaceItemPerPage(state, payload)
        {
        state.spaceRaceItemPerPage = payload;
        },
      setRetiredSpaceRaceItemPerPage(state, payload)
        { 
        state.retiredSpaceRaceItemPerPage = payload;
        },
      setSpaceRace(state, payload) {
        state.spaceRace = payload;
        },
        setRaceTypeList(state, payload)
        {
          state.racetype = payload;
        }
    },
  };
  