import { makeApiCall } from "../apiRequest";

export default {
  state: {
    yeargroups: [],
    restoryear: [],
    yearsList: [],
    yearsitemPerPage: 0,
    yeargroupID: [],
    retireitemPerPage: 0,
    yearSpellingdata: [],
    spellingItemPerPage: 0,
    wordsListData: [],
    wordsitemsPerPage: 0,
    YearGroupListBySchoolId: [],
    Yearphonic:[],
    phonicItemPerPage:0,
  },
  getters: {
    getYeargroups: (state) => state.yearsList,
    getyearItemsPerPage: (state) => state.yearsitemPerPage,
    getYearRetires: (state) => state.restoryear,
    getYearListId: (state) => state.yeargroupID,
    getRetireItemsPerPage: (state) => state.retireitemPerPage,
    Getyearspellingdata: (state) => state.yearSpellingdata,
    GetYearphonic: (state) => state.Yearphonic,
    getspellingsItemsPerPage: (state) => state.spellingItemPerPage,
    GetWordsListByIDs: (state) => state.wordsListData,
    GetWordsItemsPerPage: (state) => state.wordsitemsPerPage,
    GetYearGroupListBySchoolIds: (state) => state.YearGroupListBySchoolId,
    GetphonicItemPerPage: (state) => state.phonicItemPerPage,
  },
  actions: {
    // eslint-disable-next-line
    async addYeargroup({}, yeargroup) {
      try {
        const response = await makeApiCall(`YearGroup`, "POST", yeargroup);
        if (response.status) var res = response.data;
        if (res.status) {
          return res;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async updateYeargroup({}, yeargroup) {
      try {
        const response = await makeApiCall(`YearGroup`, "put", yeargroup);
        if (response.status && response.data.status) {
          return response.data;
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
       // eslint-disable-next-line
    async updateretire({ state }, payload) {
      try {
        const response = await makeApiCall(
          `YearGroup/Retire/${payload}`,
          "put"
        );
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },   // eslint-disable-next-line
    async updaterestore({ state }, payload) {
      try {
        const response = await makeApiCall(
          `YearGroup/Restore/${payload}`,
          "put"
        );
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getYeargroup({ commit }, obj) {
      const response = await makeApiCall(
        `YearGroup?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
        "GET"
      );
      if (response.status) var res = response.data;
      if (res.status && res.data != null && res.data.yearGroups.length >= 0) {
        commit("setyearList", res.data.yearGroups);
        commit("setYearItemPerPage", res.data.totalItems);
      }
  
      return response.data;
    },
    async getYeargroupListBySchool({ commit }, obj) {
      const response = await makeApiCall(
        `YearGroup/GetYearGroupListBySchool?userId=${obj.userId}`,
        "GET"
      );
      if (
        response.status &&
        response.data.status &&
        response.data.data?.length >= 0
      ) {
        commit("setyearList", response.data.data);
      }
      return response.data;
    },
    async getYearRetire({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `YearGroup/GetAllRetiredYearGroups?pageNo=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}`,
          "get"
        );
        if (response.status) var res = response.data;
        if (
          res.status &&
          res.data &&
          res.data.yearGroups &&
          res.data.yearGroups.length >= 0
        ) {
          commit("SET_RESTOREGYEAR", res.data.yearGroups);
          commit("setRetireItemPerPage", res.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetYearGroupListBySchoolId({ commit }, obj) {
      try {
        const response = await makeApiCall(
          `YearGroup/GetYearGroupListBySchool?schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data?.data) {
          commit("SetYearGroupListBySchool", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getYearList({ commit }) {
      try {
        const response = await makeApiCall(`YearGroup/GetYearGroupList`, `GET`);
        if (
          response.status &&
          response.data.status &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          commit("setYearIdList", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetSpellingListByID({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `YearGroup/ViewSpellingList?pageNo=${payload.page}&pageSize=${payload.pageSize}&yearGroupId=${payload.yearGroupId}`,
          "get"
        );
        if (
          response.status &&
          response.data.status &&
          response.data.data &&
          response.data.data.words
        ) {
          const { words, totalItems } = response.data.data;
          commit("SetYearspellingdata", words);
          commit("setSpellingItemPerPage", totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetPhonicListByID({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `YearGroup/ViewPhonicsList?pageNo=${payload.page}&pageSize=${payload.pageSize}&yearGroupId=${payload.yearGroupId}`,
          "get"
        );
        if (
          response.status &&
          response.data.status &&
          response.data.data &&
          response.data.data.words
        ) {
          const { words, totalItems } = response.data.data;
          commit("SetYearphonicdata", words);
          commit("setphonicItemPerPage", totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetWordsListByID({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `YearGroup/ViewWords?pageNo=${payload.page}&pageSize=${payload.pageSize}&yearGroupId=${payload.yearGroupId}`,
          "get"
        );
        if (
          response.status &&
          response.data.status &&
          response.data.data &&
          response.data.data.words
        ) {
          const { words, totalItems } = response.data.data;
          commit("SetYearWordsdata", words);
          commit("setWordsItemPerPage", totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    ADD_YEARGROUP(state, yeargroup) {
      state.yeargroups = yeargroup;
    },
    SET_YEARGROUPS(state, yeargroups) {
      state.yeargroups = yeargroups;
    },
    SET_RESTOREGYEAR(state, restoryear) {
      state.restoryear = restoryear;
    },
    setyearList(state, payload) {
      state.yearsList = payload;
    },
    setYearItemPerPage(state, payload) {
      state.yearsitemPerPage = payload;
    },
    setYearIdList(state, payload) {
      state.yeargroupID = payload;
    },
    SetYearspellingdata(state, payload) {
      state.yearSpellingdata = payload;
    },
    setRetireItemPerPage(state, payload) {
      state.retireitemPerPage = payload;
    },
    setSpellingItemPerPage(state, payload) {
      state.spellingItemPerPage = payload;
    },
    SetYearWordsdata(state, payload) {
      state.wordsListData = payload;
    },
    setWordsItemPerPage(state, payload) {
      state.wordsitemsPerPage = payload;
    },
    SetYearGroupListBySchool(state, payload) {
      state.YearGroupListBySchoolId = payload;
    },
    SetYearphonicdata (state, payload)
    {
      state.Yearphonic = payload;
    },
    setphonicItemPerPage(state, payload)
    {
      state.phonicItemPerPage = payload;
    }
  },
};
