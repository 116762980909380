let SchoolRoute = [
  {
    path: "/school/dashboard",
    component: () => import("../views/Layout/SchoolLayout/DashboardLayout.vue"),
    redirect: "/school/dashboard",
    children: [
      {
        path: "/school/dashboard",
        component: () => import("../views/GlobalAdmin/Welcome.vue"),
      },
      {
        path: "/school/school",
        component: () => import("../views/SchoolAdmin/School.vue"),
      },
      {
        path: "/school/users",
        component: () => import("../views/SchoolAdmin/Users.vue"),
      },
      {
        path: "/school/classes",
        component: () => import("../views/SchoolAdmin/Classes.vue"),
      },
      {
        path: "/school/pupil",
        component: () => import("../views/SchoolAdmin/Pupil.vue"),
      },
      {
        path: "/school/spacerace",
        component: () => import("../views/SchoolAdmin/SpaceRace.vue"),
      },
      {
        path: "/school/groupandsetting",
        component: () => import("../views/SchoolAdmin/GroupAndSetting.vue"),
      },
      {
        path: "/school/customspellinglist",
        component: () => import("../views/SchoolAdmin/SpellingList.vue"),
      },
      {
        path: "/school/spellinglist",
        component: () => import("../views/SchoolAdmin/BuiltInSpellingList.vue"),
      },
      {
        path: "/school/customword",
        component: () => import("../views/SchoolAdmin/CustomWord.vue"),
      },
      {
        path: "/school/phoniclist",
        component: () => import("../views/SchoolAdmin/PhonicList.vue"),
      },
      {
        path: "/school/customphoniclist",
        component: () => import("../views/SchoolAdmin/CustomPhonicList.vue"),
      },
      {
        path: "/school/report",
        component: () => import("../views/SchoolAdmin/Reports.vue"),
      },
    ],
  },
];

export default SchoolRoute;
