/* eslint-disable no-unused-vars */
import {
  makeApiCall,
  PostFormData,
  PutFormData,
  Restore,
  Retire,
} from "../apiRequest";

export default {
  state: {
    wordList: [],
    wordListByParent: [],
    retiredWordList: [],
    retiredWordListByParent: [],
    word: {},
    wordSchool: {},
    teacherwordList: [],
    wordListID: [],
    wordQuestionListID: [],
    schoolwordList: [],
    wordListIDSchool: [],
    schoolretiredWordList: [],
    teacherretiredWordList: [],
    allWordListByParent: [],
    allWordListBySchool: [],
    allWordListBySchoolFull: [],
    allWordListByTeacher: [],
    allWordListByStaffs: [],
    allFullWordListBySchool: [],
    allFullWordListBySchools: [],
    allFullWordListBySchoolsType: [],
  },
  getters: {
    getWords: (state) => state.wordList,
    getWordsByParent: (state) => state.wordListByParent,
    getRetiredWords: (state) => state.retiredWordList,
    getRetiredWordByParent: (state) => state.retiredWordListByParent,
    getWord: (state) => state.word,
    getWordSchool: (state) => state.wordSchool,
    getWordTeacher: (state) => state.teacherwordList,
    getWordIds: (state) => state.wordListID,
    getWordQuestionIds: (state) => state.wordQuestionListID,
    getWordIdsSchool: (state) => state.wordListIDSchool,
    getItemsPerPage: (state) => state.itemPerPage,
    getWordsSchool: (state) => state.schoolwordList,
    getRetiredWordsSchool: (state) => state.schoolretiredWordList,
    getRetiredWordsTeacher: (state) => state.teacherretiredWordList,
    getAllWordsByParent: (state) => state.allWordListByParent,
    getAllWordsBySchool: (state) => state.allWordListBySchool,
    getAllWordsBySchoolFull: (state) => state.allWordListBySchoolFull,
    getAllWordsByTeacher: (state) => state.allWordListByTeacher,
    getAllWordsByStaffs: (state) => state.allWordListByStaffs,
    getAllFullWordsBySchool: (state) => state.allFullWordListBySchool,
    GetAllFullWordsBySchool: (state) => state.allFullWordListBySchools,
    GetAllFullWordsBySchoolType: (state) => state.allFullWordListBySchoolsType,
  },
  actions: {
    async getAllWordsByParent({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Word/GetWordListByUser?userId=${userId}`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null && res.data.length >= 0) {
              commit("setAllWordByParent", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllWordsByTeacher({ commit }, StaffId) {
      try {
        const response = await makeApiCall(
          `Word/GetWordListByStaff?creatorStaffId=${StaffId}`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null && res.data.length >= 0) {
              commit("setAllWordByTeacher", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllWordsByStaffs({ commit }, StaffId) {
      try {
        const response = await makeApiCall(
          `Word/GetCustomWordListByStaff?creatorStaffId=${StaffId}`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null && res.data.length >= 0) {
              commit("setAllWordByStaffs", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllWordsBySchool({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Word/GetWordListBySchool?schoolId=${userId}`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null && res.data.length >= 0) {
              commit("setAllWordBySchool", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async getAllWordsBySchoolFulls({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Word/GetFullWordListBySchool?schoolId=${userId}`,
          `GET`
        );
        const res = response.data;
        if (res.data) {
          commit("setAllWordBySchoolFulls", res.data);
        
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllFullWordsBySchool({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Word/GetFullWordListBySchool?schoolId=${userId}`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null && res.data.length >= 0) {
              commit("setAllFullWordBySchool", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllFullWordsBySchools({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Word/GetFullWordListBySchool?schoolId=${userId}`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null && res.data.length >= 0) {
              commit("setAllFullWordBySchools", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllFullWordsBySchoolsType({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Word/GetFullWordsListWithTypeBySchool?schoolId=${userId}`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null && res.data.length >= 0) {
              commit("setAllFullWordBySchoolsType", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getWord({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Word?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.words.length >= 0) {
                commit("setWordList", res.data.words);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getWordSchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Word/GetAllWordsBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.words.length >= 0) {
                commit("setWordListSchool", response.data.data.words);
                commit("setItemPerPage", response.data.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getWordTeacher({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Word/GetAllWordsByStaff?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&creatorStaffId=${obj.creatorStaffId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.words.length >= 0) {
                commit("setWordListTeacher", response.data.data.words);
                commit("setItemPerPage", response.data.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getWordByParent({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Word/GetAllWordsByUser?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.words.length >= 0) {
                commit("setWordListByParent", res.data.words);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredWord({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Word/GetAllRetiredWords?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.words.length >= 0) {
                commit("setRetiredWordList", res.data.words);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredWordByParent({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Word/GetAllRetiredWordsByUser?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.words.length >= 0) {
                commit("setRetiredWordListByParent", res.data.words);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredWordTeacher({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Word/GetAllRetiredWordsByStaff?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&creatorStaffId=${obj.creatorStaffId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.words.length >= 0) {
                commit("setRetiredWordListTeacher", res.data.words);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredWordSchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Word/GetAllRetiredWordsBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.words.length >= 0) {
                commit("setRetiredWordListSchool", res.data.words);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getWordById({ commit }, wordId) {
      try {
        const response = await makeApiCall(`Word/${wordId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setWord", res.data);
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getWordByIdSchool({ commit }, wordId) {
      try {
        const response = await makeApiCall(`Word/${wordId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setWordSchool", res.data);
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getWordsId({ commit }, payload) {
      try {
        const response = await makeApiCall(`Word/GetWordList`, `GET`);
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.length > 0) {
              commit("setwordIdList", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getQuestionWordsId({ commit }, payload) {
      try {
        const response = await makeApiCall(`Word/GetWordListForQuestions`, `GET`);
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.length > 0) {
              commit("setwordIdQuestionList", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async checkIsWordExistence({ commit }, obj) {
      try {
        const response = await makeApiCall(
          `Word/CheckIsWordExist?name=${obj.name}`
        );
        return response.data;
      } catch (error) {
        console.error("Error checking word existence:", error);
        throw error;
      }
    },

    async checkWordExistence({ commit }, obj) {
      try {
        const response = await makeApiCall(
          `Word/CheckWordExist?id=${obj.id}&name=${obj.name}&schoolId=${obj.schoolId}`
        );
        return response.data;
      } catch (error) {
        console.error("Error checking word existence:", error);
        throw error;
      }
    },

    async checkWordExistenceStaff({ commit }, obj) {
      try {
        const response = await makeApiCall(
          `Word/CheckWordExist?id=${obj.id}&name=${obj.name}&creatorStaffId=${obj.schoolId}`
        );
        return response.data;
      } catch (error) {
        console.error("Error checking word existence:", error);
        throw error;
      }
    },

    async checkWordExistenceGlobal({ commit }, obj) {
      try {
        const response = await makeApiCall(
          `Word/CheckWordExist?id=${obj.id}&name=${obj.name}&userId=${obj.schoolId}`
        );
        return response.data;
      } catch (error) {
        console.error("Error checking word existence:", error);
        throw error;
      }
    },

    async getWordsIdSchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(`Word/GetWordListBySchool?schoolId=${obj.schoolId}`, `GET`);
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.length > 0) {
              commit("setwordIdListSchool", res.data.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addWord({ commit }, obj) {
      return PostFormData("Word", obj).then((res) => {
        return res.data;
      });
    },
    async editWord({ commit }, obj) {
      return PutFormData("Word", obj).then((res) => {
        return res.data;
      });
    },

    // eslint-disable-next-line
    async restoreWord({}, wordId) {
      try {
        const response = await makeApiCall(`Word/Restore/${wordId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retireWord({}, wordId) {
      try {
        const response = await makeApiCall(`Word/Retire/${wordId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setAllWordByParent(state, payload) {
      state.allWordListByParent = payload;
    },
    setAllWordBySchool(state, payload) {
      state.allWordListBySchool = payload;
    },
    setAllWordBySchoolFulls(state, payload) {
      state.allWordListBySchoolFull = payload;
    },
    setAllWordByTeacher(state, payload) {
      state.allWordListByTeacher = payload;
    },
    setAllWordByStaffs(state, payload) {
      state.allWordListByStaffs = payload;
    },
    setAllFullWordBySchool(state, payload) {
      state.allFullWordListBySchool = payload;
    },
    setAllFullWordBySchools(state, payload) {
      state.allFullWordListBySchools = payload;
    },
    setAllFullWordBySchoolsType(state, payload) {
      state.allFullWordListBySchoolsType = payload;
    },
    setWordList(state, payload) {
      state.wordList = payload;
    },
    setWordListByParent(state, payload) {
      state.wordListByParent = payload;
    },
    setRetiredWordList(state, payload) {
      state.retiredWordList = payload;
    },
    setRetiredWordListByParent(state, payload) {
      state.retiredWordListByParent = payload;
    },
    setWord(state, payload) {
      state.word = payload;
    },
    setwordIdList(state, payload) {
      state.wordListID = payload;
    },
    setwordIdQuestionList(state, payload) {
      state.wordQuestionListID = payload;
    },
    setwordIdListSchool(state, payload) {
      state.wordListIDSchool = payload;
    },
    setWordListSchool(state , payload) {
      state.schoolwordList = payload;
    },
    setWordListTeacher(state , payload) {
      state.teacherwordList = payload;
    },
    setRetiredWordListSchool(state, payload) {
      state.schoolretiredWordList = payload;
    },
    setRetiredWordListTeacher(state, payload) {
      state.teacherretiredWordList = payload;
    },
    setWordSchool(state, payload) {
      state.wordSchool = payload;
    },
  },
};