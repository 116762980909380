/* eslint-disable no-unused-vars */
import {
    makeApiCall,
    PostFormData,
    PutFormData,
  } from "../apiRequest";

  export default {
    state: {
      sentenceList: [],
      sentencedataList: [],
      sentencedata: {},
      retiredSentenceDataList: [],
      sentenceListID: [],
      sentence: {}
    },
    getters: {
      getSentences: (state) => state.sentenceList,
      getSentenceLists: (state) => state.sentencedataList,
      getRetiredSentenceLists: (state) => state.retiredSentenceDataList,
      getSentenceData: (state) => state.sentencedata,
      getSentenceIds: (state) => state.sentenceListID,
      getSentence: (state) => state.sentence,
    },
    actions: {
        async getSentenceData({ commit, rootState }, obj) {
            try {
              const response = await makeApiCall(
                `Sentence?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
                `GET`
              );
              if (response.status) {
                var res = response.data;
                if (res.status) {
                  if (res.data != null) {
                    if (res.data.sentences.length >= 0) {
                      commit("setSentenceDataList", res.data.sentences);
                      commit("setItemPerPage", res.data.totalItems);
                    }
                  }
                }
              }
      
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          async getRetiredSentenceData({commit}, pagination) {
            try {
              const response = await makeApiCall(
                `Sentence/GetAllRetiredSentences?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`,
                `GET`
              );
              const res = response.data;
              if (res?.status && res.data?.sentences?.length >= 0) {
                      commit("setRetiredSentenceDataList", res.data.sentences);
                      commit("setItemPerPage", res.data.totalItems);
                    }
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          async getSentencesId({ commit }, payload) {
            try {
              const response = await makeApiCall(
                `Sentence/GetSentenceList`,
                `GET`
              );
              if (response.status) var res = response.data;
              if (res.status) {
                if (res.data != null) {
                  if (res.data.length > 0) {
                    commit("setsentenceIdList", res.data);
                  }
                }
              }
              return response.data;
            }
            catch (error) {
      
              console.error(error);
              throw error;
            }
          },
          async getSentence({ commit, rootState }, obj) {
            try {
              const response = await makeApiCall(
                `Sentence?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
                `GET`
              );
              if (response.status) {
                var res = response.data;
                if (res.status) {
                  if (res.data != null) {
                    if (res.data.sentences.length >= 0) {
                      commit("setSentenceList", res.data.sentences);
                      commit("setItemPerPage", res.data.totalItems);
                    }
                  }
                }
              }
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          async getSentenceById({ commit }, sentenceId) {
            try {
              const response = await makeApiCall(`Sentence/${sentenceId}`, `GET`);
              if (response.status) {
                var res = response.data;
                if (res.status) {
                  if (res.data != null) {
                    commit("setSentence", res.data);
                  }
                }
              }
              return response.data;
            } catch (error) {
              console.error(error);
              throw error;
            }
          },
          async addSentenceData({ commit }, obj) {
            return PostFormData("Sentence", obj).then((res) => {
              return res.data;
            });
          },
          async editSentenceData({ commit }, obj) {
            return PutFormData("Sentence", obj).then((res) => {
              return res.data;
            });
          },
          // eslint-disable-next-line
      async restoreSentenceData({}, sentencedataId) {
        try {
          const response = await makeApiCall(`Sentence/Restore/${sentencedataId}`, `PUT`);
          if (response.status) {
            var res = response.data;
            if (res.status) {
              return res;
            }
          }
  
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      // eslint-disable-next-line
      async retireSentenceData({}, sentencedataId) {
        try {
          const response = await makeApiCall(`Sentence/Retire/${sentencedataId}`, `PUT`);
          if (response.status) {
            var res = response.data;
            if (res.status) {
              return res;
            }
          }
  
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
        },
        mutations: {
            setSentenceDataList(state, payload) {
                state.sentencedataList = payload;
            },
            setRetiredSentenceDataList(state, payload) {
                state.retiredSentenceDataList = [...payload];
            },
            setsentenceIdList(state, payload) {
              state.sentenceListID = payload;
            },
            setSentence(state, payload) {
              state.sentence = payload;
            },
            setSentenceList(state, payload) {
              state.sentenceList = payload;
            },
        }
    }