/* eslint-disable no-unused-vars */
import {
  makeApiCall,
  PostFormData,
  PutFormData,
  Restore,
  Retire,
} from "../apiRequest";

export default {
  state: {
    phonicschemeList: [],
    retiredPhonicSchemeList: [],
    phonicscheme: {},
    PhonicSchemeAllList:[],
    PhonicSchemeListID: [],
    phonicparentList: [],
    schoolparentList: [],
    ParentItemPerPage: 0,
    SchoolItemPerPage: 0,
    StaticsOfPhonicsList:[],
  },
  getters: {
    getPhonicSchemes: (state) => state.phonicschemeList,
    getRetiredPhonicSchemes: (state) => state.retiredPhonicSchemeList,
    getPhonicScheme: (state) => state.phonicscheme,
    GetPhonicSchemeAllLists : (state)=> state.PhonicSchemeAllList,
    getPhonicSchemeIds : (state)=> state.PhonicSchemeListID,
    GetParentList: (state) => state.phonicparentList,
    GetSchoolList: (state) => state.schoolparentList,
    GetParentItemPerPage: (state) => state.ParentItemPerPage,
    GetSchoolItemPerPage: (state) => state.SchoolItemPerPage,
    GetStaticsOfPhonicsLists: (state) => state.StaticsOfPhonicsList,
  },
  actions: {
    async getPhonicScheme({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicScheme?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.phonicSchemes.length >= 0) {
                commit("setPhonicSchemeList", res.data.phonicSchemes);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredPhonicScheme({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicScheme/GetAllRetiredPhonicScheme?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.phonicSchemes.length >= 0) {
                commit("setRetiredPhonicSchemeList", res.data.phonicSchemes);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetPhonicParents({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicScheme/GetLinkedParents?id=${obj.schoolId}&pageNo=${obj.page}&pageSize=${obj.pageSize}`,
          `GET`
        );

        if (response.status && response.data?.status && response.data.data?.parents?.length >= 0) {
          commit("setParentList", response.data.data.parents);
          commit("setParentItemPerPage", response.data.data.totalCount);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetPhonicSchools({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `PhonicScheme/GetLinkedShools?id=${obj.schoolId}&pageNo=${obj.page}&pageSize=${obj.pageSize}`,
          `GET`
        );

        if (response.status && response.data?.status && response.data.data?.schools?.length >= 0) {
          commit("setSchoolList", response.data.data.schools);
          commit("setSchoolItemPerPage", response.data.data.totalCount);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPhonicSchemesId({ commit }, payload) {
      try {
        const response = await makeApiCall(`PhonicScheme/GetPhonicShemeList`, `GET`);
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.length > 0) {
              commit("setphonicSchemeIdList", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPhonicSchemeById({ commit }, phonicschemeId) {
      try {
        const response = await makeApiCall(`PhonicScheme/${phonicschemeId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setPhonicScheme", res.data);
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addPhonicScheme({ commit }, obj) {
      return PostFormData("PhonicScheme", obj).then((res) => {
        return res.data;
      });
    },
    async editPhonicScheme({ commit }, obj) {
      return PutFormData("PhonicScheme", obj).then((res) => {
        return res.data;
      });
    },
    // eslint-disable-next-line
    async removeSchoolPhonicScheme({}, phonicschemeId) {
      try {
        const response = await makeApiCall(`PhonicScheme/RemoveSchool?schoolId=${phonicschemeId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async removeParentPhonicScheme({}, phonicschemeId) {
      try {
        const response = await makeApiCall(`PhonicScheme/RemoveParent?parentId=${phonicschemeId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async restorePhonicScheme({}, phonicschemeId) {
      try {
        const response = await makeApiCall(`PhonicScheme/Restore/${phonicschemeId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retirePhonicScheme({}, phonicschemeId) {
      try {
        const response = await makeApiCall(`PhonicScheme/Retire/${phonicschemeId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetAllPhonicSchemeList({ commit }) {
      try {
          const response = await makeApiCall(
              `PhonicScheme/GetPhonicShemeList`,
              `GET`
          );
          if (response.status) var res = response.data;
          if (res.status) {
              if (res.data != null) {
                  if (res.data.length > 0) {
                      commit("SetAllPhonicSchemeList", res.data);
                  }
              }
          }
          return response.data;
      }
      catch (error) {
          console.error(error);
          throw error;
      }
  },
   // eslint-disable-next-line
   async GetStaticsOfPhonicsList({ commit, rootState }, payload) {
    try {
      debugger;
      const response = await makeApiCall(
        `PhonicsList/GetStaticsOfPhonicsList?phonicsListId=${payload.phonicsListId}&schoolId=${payload.schoolId}&startDate=${payload.startDate}&endDate=${payload.endDate}`,
        `GET`
      );
      const res = response.data;
      if (res.data) {
        commit("setStaticsOfPhonicsList", res.data);
      
      }
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
     
  },
  },
  mutations: {
    setPhonicSchemeList(state, payload) {
      state.phonicschemeList = payload;
    },
    setRetiredPhonicSchemeList(state, payload) {
      state.retiredPhonicSchemeList = payload;
    },
    setPhonicScheme(state, payload) {
      state.phonicscheme = payload;
    },
    SetAllPhonicSchemeList(state, payload){
      state.PhonicSchemeAllList = payload;
    },
    setphonicSchemeIdList(state, payload) {
      state.PhonicSchemeListID = payload;
    },
    setParentList (state , payload) {
      state.phonicparentList = payload;
    },
    setParentItemPerPage(state, payload) {
      state.ParentItemPerPage = payload;
    },
    setSchoolList (state , payload) {
      state.schoolparentList = payload;
    },
    setSchoolItemPerPage(state, payload) {
      state.SchoolItemPerPage = payload;
    },
    setStaticsOfPhonicsList(state, payload)
    {
      state.StaticsOfPhonicsList = payload;
    }
  },
};
