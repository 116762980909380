/* eslint-disable no-unused-vars */
import {
  makeApiCall,
  PostFormData,
  PutFormData,
  Restore,
  Retire,
} from "../apiRequest";

export default {
  state: {
    graphemeList: [],
    retiredGraphemeList: [],
    grapheme: {},
    graphemeListID: [],
    graphemePhonemeListID: [],
    graphemeFilesList: [],
    graphemeListIDFile: [],
  },
  getters: {
    getGraphemes: (state) => state.graphemeList,
    getRetiredGraphemes: (state) => state.retiredGraphemeList,
    getGrapheme: (state) => state.grapheme,
    getGraphemeIds: (state) => state.graphemeListID,
    getGraphemePhonemeIds: (state) => state.graphemePhonemeListID,
    getGraphemeIdsFileList: (state) => state.graphemeListIDFile,
  },
  actions: {
    async getGrapheme({ commit, rootState }, pagination) {
      try {
        const response = await makeApiCall(
          `Grapheme?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}`,
          `GET`
        );
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.graphemes.length >= 0) {
              commit("setGraphemeList", res.data.graphemes);
              commit("setItemPerPage", res.data.totalItems);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredGrapheme({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Grapheme/GetAllRetiredGraphemes?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        const res = response.data;
          if (res?.status && res.data?.graphemes?.length >= 0) {
                commit("setRetiredGraphemeList", res.data.graphemes);
                commit("setItemPerPage", res.data.totalItems);
              }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getGraphemeById({ commit }, graphemeId) {
      try {
        const response = await makeApiCall(`Grapheme/${graphemeId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setGrapheme", res.data);
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getGraphemesId({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `Grapheme/GetGraphemeList`,
          `GET`
        );
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.length > 0) {
              commit("setGraphemeIdList", res.data);
            }
          }
        }
        return response.data;
      }
      catch (error) {

        console.error(error);
        throw error;
      }
    },
    async getGraphemesPhonemeId({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `Grapheme/GetAudioPhonemeList`,
          `GET`
        );
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.length > 0) {
              commit("setGraphemePhonemeIdList", res.data);
            }
          }
        }
        return response.data;
      }
      catch (error) {

        console.error(error);
        throw error;
      }
    },
    async getGraphemesIdFileList({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `Grapheme/GetGraphemeList`,
          `GET`
        );
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.length > 0) {
              commit("setGraphemeIdListFile", res.data);
            }
          }
        }
        return response.data;
      }
      catch (error) {

        console.error(error);
        throw error;
      }
    },
    async addGrapheme({ commit }, obj) {
      return PostFormData("Grapheme", obj).then((res) => {
        return res.data;
      });
    },
    async editGrapheme({ commit }, obj) {
      return PutFormData("Grapheme", obj).then((res) => {
        return res.data;
      });
    },
    // eslint-disable-next-line
    async restoreGrapheme({}, graphemeId) {
      try {
        const response = await makeApiCall(
          `Grapheme/Restore/${graphemeId}`,
          `PUT`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retireGrapheme({}, graphemeId) {
      try {
        const response = await makeApiCall(
          `Grapheme/Retire/${graphemeId}`,
          `PUT`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setGraphemeList(state, payload) {
      state.graphemeList = payload;
    },
    setRetiredGraphemeList(state, payload) {
      state.retiredGraphemeList = [...payload];
    },
    setGrapheme(state, payload) {
      state.grapheme = payload;
    },
    setGraphemeIdList(state, payload) {
      state.graphemeListID = payload;
    },
    setGraphemePhonemeIdList(state, payload) {
      state.graphemePhonemeListID = payload;
    },
    setGraphemeIdListFile(state, payload) {
      state.graphemeListIDFile = payload;
    },
    setGraphemeFilesList(state, payload) {
      state.graphemeFilesList = payload;
    },
  },
};
