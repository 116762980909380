/* eslint-disable no-unused-vars */
import { makeApiCall } from "../apiRequest";

export default {
  state: {
    pupilList: [],
    pupilListByTeacher: [],
    pupilListSchool: [],
    pupilListSchoolFull: [],
    pupilListStaffFull: [],
    retiredPupilList: [],
    pupil: {},
    AllPupilList: [],
    LinkedStaffList: [],
    LinkedClassList: [],
    ItemPerPage: 0,
    LinkedClassItemPerPage: 0,
    LinkedStaffItemPerPage: 0,
    LinekdStaffList: [],
    pupilsLinkedClasses: [],
    pupilListBySchoolId: [],
    pupilListBySchoolIdFull: [],
    allPupilUsernames: [],
  },
  getters: {
    getPupils: (state) => state.pupilList,
    getPupilsByTeacher: (state) => state.pupilListByTeacher,
    getAllPupilListBySchool: (state) => state.pupilListSchool,
    getAllPupilListBySchoolFull: (state) => state.pupilListSchoolFull,
    getAllPupilListByStaffFull: (state) => state.pupilListStaffFull,
    getAllPupilListByParent: (state) => state.allPupilUsernames,
    getRetiredPupils: (state) => state.retiredPupilList,
    getPupil: (state) => state.pupil,
    GetAllPupilLists: (state) => state.AllPupilList,
    GetLinkedStaffList: (state) => state.LinkedStaffList,
    GetLinkedClassList: (state) => state.LinkedClassList,
    GetItemPerPage: (state) => state.ItemPerPage,
    GetLinkedStaffItemPerPage: (state) => state.LinkedStaffItemPerPage,
    GetLinkedClassItemPerPage: (state) => state.LinkedClassItemPerPage,
    GetPupilListBySchoolIds: (state) => state.pupilListBySchoolId,
    GetPupilListBySchoolIdsFull: (state) => state.pupilListBySchoolIdFull,
  },
  actions: {
    async getAllPupilListByParent({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Pupil/GetAllPupilListByUser?userId=${userId}`,
          `GET`
        );
        if (response.status && response.data.status) {
          commit("setAllPupilListByParent", response.data.data);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPupilByTeacher({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/GetAllPupilsByUser?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
          `GET`
        );
        if (response.status && response.data.status) {
          const responseData = response.data.data;
          if (
            responseData &&
            responseData.pupils &&
            responseData.pupils.length >= 0
          ) {
            commit("setPupilListByTeacher", responseData.pupils);
            commit("setItemPerPage", responseData.totalItems);
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPupil({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status && response.data.status) {
          const responseData = response.data.data;
          if (
            responseData &&
            responseData.pupils &&
            responseData.pupils.length >= 0
          ) {
            commit("setPupilList", responseData.pupils);
            commit("setItemPerPage", responseData.totalItems);
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async movePupilsToClass({ commit, rootState }, payload) {
      try {
        const response = await makeApiCall(
          `Pupil/MovePupilToClass`,
          `PUT`,
          payload
        );
        if (response.status && response.data.status) {
          return response.data;
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getPupilListByUser({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/GetPupilListByUser?userId=${obj.userId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data?.data?.length >= 0
        ) {
          commit("setPupilList", response.data.data);
          commit("setItemPerPage", response.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllPupilsByUser({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/GetAllPupilsByUser?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data.data?.pupils?.length >= 0
        ) {
          commit("setPupilList", response.data.data.pupils);
          commit("setItemPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllPupilsBySchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/GetAllPupilsBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data.data?.pupils?.length >= 0
        ) {
          commit("setPupilList", response.data.data.pupils);
          commit("setItemPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getAllPupilListBySchool({ commit, rootState }, schoolId) {
      try {
        const response = await makeApiCall(
          `Pupil/GetPupilListBySchool?schoolId=${schoolId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data.data?.length >= 0 ) {
          commit("setPupilListSchool", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllPupilListBySchoolFull({ commit, rootState }, schoolId) {
      try {
        const response = await makeApiCall(
          `Pupil/GetAssignedPupilListBySchool?schoolId=${schoolId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data.data?.length >= 0 ) {
          commit("setPupilListSchoolFull", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllPupilListByStaffFull({ commit, rootState }, schoolId) {
      try {
        const response = await makeApiCall(
          `Pupil/GetAssignedPupilListBySchool?schoolId=${schoolId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data.data?.length >= 0 ) {
          commit("setPupilListStaffFull", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getAllPupilListByClass({ commit, rootState }, classId) {
      try {
        const response = await makeApiCall(
          `Pupil/GetPupilListByClass`,
          `PUT`, classId
        );
        if(response.status && response.data?.status && response.data.data){
            commit("setPupilList", response.data.data);
          }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredPupil({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/GetAllRetiredPupils?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.pupils.length >= 0) {
                commit("setRetiredPupilList", res.data.pupils);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getPupilById({ commit }, pupilId) {
      try {
        const response = await makeApiCall(`Pupil/${pupilId}`, `GET`);
        if (response.status && response.data?.status && response.data.data) {
          commit("setPupil", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addPupil({ commit }, payload) {
      try {
        const response = await makeApiCall(`Pupil`, `POST`, payload);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editPupil({ commit }, payload) {
      try {
        const response = await makeApiCall(`Pupil`, `PUT`, payload);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async restorePupil({}, pupilId) {
      try {
        const response = await makeApiCall(`Pupil/Restore/${pupilId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retirePupil({}, pupilId) {
      try {
        const response = await makeApiCall(`Pupil/Retire/${pupilId}`, `PUT`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getStaffLinkedClasses({ commit }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/ViewLinkedClasses?pageNo=${obj.page}&pageSize=${obj.pageSize}&staffId=${obj.pupilId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setPupilsLinkedClasses", res.data.classes);
              commit("setmovepupilItemPerPage", res.data.totalItems);
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllRetiredPupilsBySchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/GetAllRetiredPupilsByUser?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data.data?.pupils?.length >= 0
        ) {
          commit("setRetiredPupilList", response.data.data.pupils);
          commit("setItemPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredPupilsBySchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(`Pupil/GetAllRetiredPupilsBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`, `GET`);
        if (response.status && response.data?.status && response.data.data?.pupils?.length >= 0) {
          commit("setRetiredPupilList", response.data.data.pupils);
          commit("setItemPerPage", response.data.data.totalItems);

        }
        return response.data;
      }
      catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetAllPupilList({ commit }) {
      try {
        const response = await makeApiCall(`Pupil/GetPupilList`, `GET`);

        if (response.status) {
          var res = response.data;

          if (res.status) {
            if (res.data != null) {
              if (res.data.length > 0) {
                commit("SetAllPupilList", res.data);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error("API Error:", error);
        throw error;
      }
    },

    async GetPupilLinkedSatffMember({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/ViewLinkedStaffMember?pageNo=${obj.page}&pageSize=${obj.pageSize}&pupilId=${obj.pupilId}`,
          `GET`
        );
        if (
          response.status &&
          response.data?.status &&
          response.data.data?.staffs?.length >= 0
        ) {
          commit("setLinkedStaffList", response.data.data.staffs);
          commit("setLinkedStaffItemPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetPupilLinkedClasses({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/ViewLinkedClass?pageNo=${obj.page}&pageSize=${obj.pageSize}&pupilId=${obj.pupilId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data.data) {
          commit("setLinkedClassList", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetPupilListBySchool({ commit }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/GetPupilListBySchool?schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data?.data) {
          commit("setPupilListBySchoolId", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetPupilListBySchoolFull({ commit }, obj) {
      try {
        const response = await makeApiCall(
          `Pupil/GetAssignedPupilListBySchool?schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data?.data) {
          commit("setPupilListBySchoolIdFull", response.data.data);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setAllPupilListByParent(state, payload) {
      state.allPupilUsernames = payload;
    },
    setPupilList(state, payload) {
      state.pupilList = payload;
    },
    setPupilListByTeacher(state, payload) {
      state.pupilListByTeacher = payload;
    },
    setPupilListSchool(state, payload) {
      state.pupilListSchool = payload;
    },
    setPupilListSchoolFull(state, payload) {
      state.pupilListSchoolFull = payload;
    },
    setPupilListStaffFull(state, payload) {
      state.pupilListStaffFull = payload;
    },
    setRetiredPupilList(state, payload) {
      state.retiredPupilList = payload;
    },
    setPupil(state, payload) {
      state.pupil = payload;
    },
    SetAllPupilList(state, payload) {
      state.AllPupilList = payload;
    },
    setLinkedClassList(state, payload) {
      state.LinkedClassList = payload;
    },
    setLinkedStaffList(state, payload) {
      state.LinkedStaffList = payload;
    },
    setItemPerPage(state, payload) {
      state.ItemPerPage = payload;
    },
    setLinkedStaffItemPerPage(state, payload) {
      state.LinkedStaffItemPerPage = payload;
    },
    setLinkedClassItemPerPage(state, payload) {
      state.LinkedClassItemPerPage = payload;
    },
    setPupilsLinkedClasses(state, payload) {
      state.pupilsLinkedClasses = payload;
    },
    setPupilListBySchoolId(state, payload) {
      state.pupilListBySchoolId = payload;
    },
    setPupilListBySchoolIdFull(state, payload) {
      state.pupilListBySchoolIdFull = payload;
    },
  },
};
