/* eslint-disable no-unused-vars */
import {
  makeApiCall,
  PostFormData,
  PutFormData,
  Restore,
  Retire,
} from "../apiRequest";

export default {
  state: {
    schoolList: [],
    schoolListBySchool: [],
    retiredSchoolList: [],
    school: {},
    schoolstaffList:[],
    StaffItemPerPage:0,
    StaffLoginItemPerPage: 0,
    LoginsList:[],
    LoginsItemPerPage:0,
    spellingListData:[],
    spellingItemPerPage:0,
    allschoolList:[],
    schoolsBySchool: [],
    schoolphonic:[],
    schoolphonicItemPerPage:0,
    schoolListId:0,
    selectedSchoolId:null,
    schoolWondeschoolId:0,

  },
  getters: {
    Getschooltoken: (state) => state.schoolListId,
    getSchools: (state) => state.schoolList,
    getSchoolsBySchool: (state) => state.schoolsBySchool,
    getSchoolsListBySchools : (state) => state.schoolListBySchool,
    getRetiredSchools: (state) => state.retiredSchoolList,
    getSchool: (state) => state.school,
    GetStaffList: (state) => state.schoolstaffList,
    GetloginStaffItemPerPage: (state) => state.StaffLoginItemPerPage,
    GetLoginLists: (state) => state.LoginsList,
    GetspellingListData: (state) => state.spellingListData,
    GetspellingItemPerPage: (state) => state.spellingItemPerPage,
    GetLoginsItemPerPage: (state) => state.LoginsItemPerPage,
    GetAllSchoolLists: (state) => state.allschoolList,
    Getschoolphonic: (state) => state.schoolphonic,
    GetschoolphonicItemPerPage: (state) => state.schoolphonicItemPerPage,
    GetselectedSchoolIds: (state) => state.selectedSchoolId,
    GetWondeAccessTokens:  (state) => state.schoolWondeschoolId,

  },
  actions: {
      GetselectedSchoolId({commit}, payload)
     {
      commit("SetselectedSchoolId", payload);
     },
    async LoginSchoolById({ commit }, schoolId) {
      try {
        const response = await makeApiCall(`Auth/LoginAsSchool?schoolId=${schoolId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("SetSchooltoken", res.data);
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetAllSchoolList({ commit }) {
      try {
        const response = await makeApiCall(
          `School/GetSchoolsList`,
          `GET`
        );
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.length > 0) {
              commit("SetAllSchoolList", res.data);
            }
          }
        }
        return response.data;
      }
      catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getSchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `School?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.schools.length >= 0) {
                commit("setSchoolList", res.data.schools);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getSchoolsBySchoolId({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `School/GetAllSchoolsBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.schools.length >= 0) {
                commit("setSchoolsBySchool", res.data.schools);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetSchoolSatffMember({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `School/ViewStaffsLogin?pageNo=${obj.page}&pageSize=${obj.pageSize}&schoolId=${obj.schoolId}`,
          `GET`
        );

        if (response.status && response.data?.status && response.data.data?.staffs?.length >= 0) {
          commit("setStaffList", response.data.data.staffs);
          commit("setStaffLoginItemPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetSchoolLogins({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `School/ViewClassesLogin?pageNo=${obj.page}&pageSize=${obj.pageSize}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data.data?.classes?.length >= 0) {
          commit("setLoginsList", response.data.data.classes);
          commit("setLoginsItemPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetschoolSpellingList({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `School/ViewSpellingList?pageNo=${obj.page}&pageSize=${obj.pageSize}&schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status && response.data?.status && response.data.data?.words?.length >= 0) {
          commit("setspellingList", response.data.data.words);
          commit("setspellingItemPerPage", response.data.data.totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredSchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `School/GetAllRetiredSchools?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.schools.length >= 0) {
                commit("setRetiredSchoolList", res.data.schools);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getRetiredSchoolBySchoolId({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `School/GetAllRetiredSchoolsBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.schools.length >= 0) {
                commit("setRetiredSchoolList", res.data.schools);
                commit("setItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getSchoolById({ commit }, schoolId) {
      try {
        const response = await makeApiCall(`School/${schoolId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("setSchool", res.data);
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetWondeAccessToken({ commit }, schoolId) {
      try {
        const response = await makeApiCall(`School/GetWondeAccessToken?schoolId=${schoolId}`, `GET`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              commit("SetWondeAccessToken", res.data);
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async addSchool({ commit }, payload) {
      try {
        const response = await makeApiCall(`School`, `POST`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
     // eslint-disable-next-line
    async ImportSchool({ }, schoolId) {
      try {
        const response = await makeApiCall(`School/ImportSchool?schoolId=${schoolId}`, `Post`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
     // eslint-disable-next-line
    async ImportPupil({ }, schoolId) {
      try {
        const response = await makeApiCall(`Pupil/ImportPupils?schoolId=${schoolId}`, `Post`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
      // eslint-disable-next-line
      async ImportClass({ }, schoolId) {
        try {
          
          const response = await makeApiCall(`Class/ImportClasses?schoolId=${schoolId}`, `Post`);
          if (response.status) {
            var res = response.data;
            if (res.status) {
              return res;
            }
          }
  
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },

    async editSchool({ commit }, payload) {
      try {
        const response = await makeApiCall(`School`, `PUT`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async AddWonde({ commit }, payload) {
      try {
        const response = await makeApiCall(`School/ImportSchoolByWondeSchoolId`, `POST`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async ShareCredntials({ commit }, payload) {
      try {
        const response = await makeApiCall(`Staff/ShareCredentialsToAllStaff`, `POST`, payload);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  
    // eslint-disable-next-line
    async restoreSchool({ }, schoolId) {
      try {
        const response = await makeApiCall(`School/Restore/${schoolId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retireSchool({ }, schoolId) {
      try {
        const response = await makeApiCall(`School/Retire/${schoolId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getSubSchoolsListBySchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `School/GetSubSchoolsList?schoolId=${obj.schoolId}`,
          `GET`
        );
        if (response.status && response.data?.data) {
          
                commit("setSchoolListBySchool", response.data.data);
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async GetPhonicschoolListByID({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `School/ViewPhonicsList?pageNo=${payload.page}&pageSize=${payload.pageSize}&schoolId=${payload.schoolId}`,
          "get"
        );
        if (
          response.status &&
          response.data.status &&
          response.data.data &&
          response.data.data.phonicsLists
        ) {
          const { phonicsLists, totalItems } = response.data.data;
          commit("Setschoolphonicdata", phonicsLists);
          commit("setphonicphonicItemPerPage", totalItems);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    
  },
  mutations: {
    SetWondeAccessToken( state, payload)
    {
      state.schoolWondeschoolId = payload;
    },
    SetSchooltoken( state, payload)
    {
      state.schoolListId = payload;
    },
    setSchoolList(state, payload) {
      state.schoolList = payload;
    },
    setSchoolsBySchool(state, payload) {
      state.schoolsBySchool = payload;
    },
    setSchoolListBySchool(state, payload) {
      state.schoolListBySchool = payload;
    },
    setStaffList (state , payload)
    {
      state.schoolstaffList = payload;
    },
    setRetiredSchoolList(state, payload) {
      state.retiredSchoolList = payload;
    },
    setSchool(state, payload) {
      state.school = payload;
    },
    setStaffLoginItemPerPage(state, payload) {
      state.StaffLoginItemPerPage = payload;
    },
    setLoginsList(state, payload) {
      state.LoginsList = payload;
    },
    setLoginsItemPerPage(state, payload) {
      state.LoginsItemPerPage = payload;
    },
    setspellingList(state, payload) {
      state.spellingListData = payload;
    },
    setspellingItemPerPage(state, payload) {
      state.spellingItemPerPage = payload;
    },
    SetAllSchoolList(state, payload) {
      state.allschoolList = payload;
    },
    Setschoolphonicdata (state, payload)
    {
      state.schoolphonic = payload;
    },
    setphonicphonicItemPerPage(state, payload)
    {
      state.schoolphonicItemPerPage = payload;
    },
    SetselectedSchoolId(state, payload)
    {
      state.selectedSchoolId = payload;
    }
  },
};
