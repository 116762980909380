import Vue from "vue";
import VueRouter from "vue-router";
import GlobalRoute from "./GlobalRoute.js";
import ParentRoute from "./ParentRoute.js";
import SchoolRoute from "./SchoolRoute.js";
import TeacherRoute from "./TeacherRoute.js";
import PagesRoute from "./PagesRoute.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Layout/AuthLayout/Authlayout.vue"),
    redirect: "/login",
    children: [
      {
        path: "/login",
        component: () => import("../views/Auth/Login.vue"),
      },
      {
        path: "/register",
        component: () => import("../views/Auth/Register.vue"),
      },
      {
        path: "/subscription",
        name: "subscription",
        component: () => import("../views/Subscription/Subscription.vue"),
      },
      {
        path: "/stripe",
        component: () => import("../views/Stripe/Stripe.vue"),
      },
      {
        path: "/thankuPage",
        component: () => import("../views/Stripe/thankuPage.vue"),
      },
      {
        path: "/forgetpassword",
        component: () => import("../views/Auth/ForgetPassword.vue"),
      },
      {
        path: "/resetpassword",
        component: () => import("../views/Auth/ResetPassword.vue"),
      },
      {
        path: "/selectionPage",
        component: () => import("../views/Auth/slsectpage.vue"),
      },
    ],
  },
  {
    path: "*",
    component: () => import("../views/PageNotFound/PageNotFound.vue"),
  },
  ...GlobalRoute,
  ...ParentRoute,
  ...SchoolRoute,
  ...TeacherRoute,
  ...PagesRoute,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.path === '/login') {
    localStorage.setItem('fromLogin', 'true');
  } else {
    localStorage.setItem('fromLogin', 'false');
  }
  next();
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};
export default router;
