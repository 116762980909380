/* eslint-disable no-unused-vars */
import {
    makeApiCall,
    PostFormData,
    PutFormData,
    Restore,
    Retire,
  } from "../apiRequest";
  
  export default {
    state: {
      classList: [],
      allClassListByTeacher: [],
      classListBySchool: [],
      classListByTeacher: [],
      retiredClassList: [],
      class: {},
      classListID:{},
      classListSchool:{},
      classListSchoolFull:[],
      pupilListByClassId: {},
      staffListByClassId: [],
      pupilsItemsPerPage: 0,
      staffItemsPerPage:0,
      ClassItemPerPage:0,
      ItemPerPage:0,
      classListBySchoolId:[],
      classListBySchoolById:[],
      ClassItemPerPageById:0.
    },
    getters: {
      getClasses: (state) => state.classList,
      getClassListByTeacher: (state) => state.allClassListByTeacher,
      getClassByTeacher: (state) => state.classListByTeacher,
      getSchoolClasses: (state) => state.classListBySchool,
      getRetiredClasses: (state) => state.retiredClassList,
      getClass: (state) => state.class,
      getClassListID:(state) => state.classListID,
      getClassListSchool:(state) => state.classListSchool,
      getClassListSchoolFull:(state) => state.classListSchoolFull,
      GetLinkedPupilsByClassIds: (state) => state.pupilListByClassId,
      GetLinkedStaffByClassIds: (state) => state.staffListByClassId,
      GetPupilsItemsPerPage: (state) => state.pupilsItemsPerPage,
      GetStaffItemsPerPage: (state) => state.staffItemsPerPage,
      GetClassItemPerPage: (state) => state.ClassItemPerPage,
      GetClassItemPerPageById: (state) => state.ClassItemPerPageById,
      GetItemPerPage: (state) => state.ItemPerPage,
      GetClassListBySchoolIds:(state) => state.classListBySchoolId,
      GetclassListBySchoolByIds: (state) => state.classListBySchoolById,
    },
    actions: {
      async getClass({ commit, rootState }, obj) {
        try {
          const response = await makeApiCall(
            `Class?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
            `GET`
          );
          if (response.status) {
            var res = response.data;
            if (res.status) {
              if (res.data != null) {
                if (res.data.classes.length >= 0) {
                  commit("setClassList", res.data.classes);
                  commit("setClassItemPerPage", res.data.totalItems);                }
              }
            }
          }
  
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      async getClassByTeacher({ commit, rootState }, obj) {
        try {
          const response = await makeApiCall(
            `Class/GetAllClassesByUser?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
            `GET`
          );
            if(response.status && response.data?.status && response.data.data?.classes?.length >= 0 ){
              commit("setSchoolClassListByTeacher", response.data.data.classes);
              commit("setClassItemPerPage", response.data.data.totalItems);
            }
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      async getAllClassesBySchool({ commit, rootState }, obj) {
        try {
          const response = await makeApiCall(
            `Class/GetAllClassesByUser?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&UserId=${obj.userId}`,
            `GET`
          );
            if(response.status && response.data?.status && response.data.data?.classes?.length >= 0 ){
              commit("setSchoolClassList", response.data.data.classes);
              commit("setClassItemPerPage", response.data.data.totalItems);
            }
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },

      async getClassesBySchool({ commit, rootState }, obj) {
        try {
          const response = await makeApiCall(
            `Class/GetAllClassesBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
            `GET`
          );
            if(response.status && response.data?.status && response.data.data?.classes?.length >= 0 ){
              commit("setSchoolClassList", response.data.data.classes);
              commit("setClassItemPerPage", response.data.data.totalItems);
            }
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      async getClassesBySchoolById({ commit, rootState }, obj) {
        try {
          const response = await makeApiCall(
            `Class/GetAllClassesBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
            `GET`
          );
            if(response.status && response.data?.status && response.data.data?.classes?.length >= 0 ){
              commit("setSchoolClassListById", response.data.data.classes);
              commit("setClassItemPerPageById", response.data.data.totalItems);
            }
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      async getAllRetiredClassesBySchool({ commit, rootState }, obj) {
        try {
          const response = await makeApiCall(
            `Class/GetAllRetiredClassesByUser?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&userId=${obj.userId}`,
            `GET`
          );
            if(response.status && response.data?.data?.classes?.length >= 0){
                  commit("setRetiredClassList", response.data.data.classes);
                  commit("setClassItemPerPage", response.data.data.totalItems);
                }
  
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      async getRetiredClassesBySchool({ commit, rootState }, obj) {
        try {
          const response = await makeApiCall(
            `Class/GetAllRetiredClassesBySchool?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}&schoolId=${obj.schoolId}`,
            `GET`
          );
            if(response.status && response.data?.data?.classes?.length >= 0){
                  commit("setRetiredClassList", response.data.data.classes);
                  commit("setClassItemPerPage", response.data.data.totalItems);
                }
  
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      async getClassById({ commit }, classId) {
        try {
          const response = await makeApiCall(`Class/${classId}`, `GET`);
          if(response.status && response.data?.status && response.data?.data){
                commit("setClass", response.data.data);
              }
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      async GetClassListBySchoolId({ commit }, obj) {
        try {
          const response = await makeApiCall(`Class/GetClassListBySchool?schoolId=${obj.schoolId}`, `GET`);
          if(response.status && response.data?.status && response.data?.data){
                commit("setClassListBySchoolId", response.data.data);
              }
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      async addClass({ commit }, payload) {
        try {
          const response = await makeApiCall(`Class`, `POST`, payload);
          if (response.status) {
            var res = response.data;
            if (res.status) {
              return res;
            }
          }
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      async editClass({ commit }, payload) {
        try {
          const response = await makeApiCall(`Class`, `PUT`, payload);
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },

    async moveClassToNextYear({ commit, rootState }, payload) {
      try {
        const response = await makeApiCall(
          `Class/MoveChildrenToNewClass?existingClassId=${payload.existingClassId}&newClassId=${payload.newClassId}`,
          `PUT`
        ); 
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
      async GetClassLinkedStaffMember({ commit, rootState }, obj) {
        try {
          const response = await makeApiCall(
            `Class/ViewLinkedStaffMember?pageNo=${obj.page}&pageSize=${obj.pageSize}&classId=${obj.classId}`,
            `GET`
          );
          if(response.status && response.data?.status && response.data.data?.staffs?.length >= 0){
              commit("setLinkedStaffList", response.data.data.staffs);
              commit("setStaffItemsPerPage", response.data.data.totalItems);
            }
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
    async getRetiredClass({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Class/GetAllRetiredClasses?pageNo=${obj.page}&pageSize=${obj.pageSize}&searchString=${obj.searchString}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.classes.length >= 0) {
                commit("setRetiredClassList", res.data.classes);
                commit("setClassItemPerPage", res.data.totalItems);
              }
            }
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async GetLinkedPupilsByClassId({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Class/ViewLinkedPupils?pageNo=${obj.page}&pageSize=${obj.pageSize}&classId=${obj.classId}`,
          `GET`
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            if (res.data != null) {
              if (res.data.pupils.length >= 0) {
                commit("setLinkedPupilList", res.data.pupils);
                commit("setPupilsItemPerPage", res.data.totalItems);
              }
            }
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    
    async getClassList({ commit }) {
      try {
        const response = await makeApiCall(
          `Class/GetClassList`,
          `GET`
        );
        if (response.status) var res = response.data;
        if (res.status) {
          if (res.data != null) {
            if (res.data.length > 0) {
              commit("setClassIdList", res.data);
            }
          }
        }
        return response.data;
      }
      catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getClassListByTeacher({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Class/GetClassListByUser?UserId=${obj}`,
          `GET`
        );
          if(response.status && response.data?.status && response.data.data?.length >= 0 ){
            commit("setClassListByTeacher", response.data.data);
          }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getClassListBySchool({ commit, rootState }, obj) {
      try {
        const response = await makeApiCall(
          `Class/GetClassListByUser?UserId=${obj.userId}`,
          `GET`
        );
          if(response.status && response.data?.status && response.data.data?.length >= 0 ){
            commit("setClassIdList", response.data.data);
          }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllClassListBySchool({ commit, rootState }, schoolId) {
      try {
        const response = await makeApiCall(
          `Class/GetClassListBySchool?SchoolId=${schoolId}`,
          `GET`
        );
          if(response.status && response.data?.status && response.data.data?.length >= 0 ){
            commit("setClassIdList", response.data.data);
          }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getClassListSchool({ commit, rootState }, schoolId) {
      try {
        const response = await makeApiCall(
          `Class/GetClassListBySchool?SchoolId=${schoolId}`,
          `GET`
        );
          if(response.status && response.data?.status && response.data.data?.length >= 0 ){
            commit("setClassListBySchool", response.data.data);
          }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getClassListSchoolFulls({ commit, rootState }, schoolId) {
      try {
        const response = await makeApiCall(
          `Class/GetClassListBySchool?SchoolId=${schoolId}`,
          `GET`
        );
          if(response.status && response.data?.status && response.data.data?.length >= 0 ){
            commit("setClassListBySchoolFull", response.data.data);
          }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async restoreClass({ }, classId) {
      try {
        const response = await makeApiCall(`Class/Restore/${classId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async retireClass({ }, classId) {
      try {
        const response = await makeApiCall(`Class/Retire/${classId}`, `PUT`);
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setClassList(state, payload) {
      state.classList = payload;
    },
    setClassListByTeacher(state, payload) {
      state.allClassListByTeacher = payload;
    },
    setSchoolClassListByTeacher(state, payload) {
      state.classListByTeacher = payload;
    },
    setSchoolClassList(state, payload) {
      state.classListBySchool = payload;
    },
    setSchoolClassListById(state, payload) {
      state.classListBySchoolById = payload;
    },
    setRetiredClassList(state, payload) {
      state.retiredClassList = payload;
    },
    setClass(state, payload) {
      state.class = payload;
    },
    setClassListBySchoolId (state,payload)
    {
      state.classListBySchoolId = payload
    },
    setClassIdList(state, payload) {
      state.classListID = payload;
    },
    setClassListBySchool(state, payload) {
      state.classListSchool = payload;
    },
    setClassListBySchoolFull(state, payload) {
      state.classListSchoolFull = payload;
    },
    setLinkedPupilList(state, payload) {
      state.pupilListByClassId = payload;
    },
    setLinkedStaffList(state, payload) {
      state.staffListByClassId = payload;
    },
    setPupilsItemPerPage(state, payload) {
      state.pupilsItemsPerPage = payload;
    },

    setClassItemPerPage(state, payload)
    {
      state.ClassItemPerPage = payload;
    },
    setClassItemPerPageById(state, payload)
    {
      state.ClassItemPerPageById = payload;
    },

    setItemPerPage(state, payload)
    {
      state.ItemPerPage = payload;
    },
    setStaffItemsPerPage(state, payload) {
      state.staffItemsPerPage = payload;
    },
  },
};
