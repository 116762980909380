/* eslint-disable no-unused-vars */
import axios from "axios";
import { makeApiCall } from "../apiRequest";

export default {
  state: {
    token: null,
    user: null,
    parentinfo: null,
    parentSubscriptionInfo: null,
    signUpDate: null,
  },
  getters: {
    //getToken: (state) => state.token,
    isAuthenticated: (state) => !!state.token,
    getUser: (state) => state.user,
    getParentInfo: (state) => state.parentinfo,
    parentAdminSubscriptionInfo: (state) => state.parentSubscriptionInfo,
    getSignUpDate: (state) => state.signUpDate,
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}Auth/Login`,
          credentials
        );
        const token = response.data;
        if (token && token.data) {
          const base64Url = token.data.split(".")[1];
          const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          const tokenData = JSON.parse(atob(base64));
          localStorage.setItem("userId", tokenData.UserId);
          //localStorage.getItem("userId");
          commit("setToken", token.data);
          localStorage.setItem("token", token.data);

          return response.data;
        }
      } catch (error) {
        console.log("Login failed:", error);
        throw error;
      }
    },
    async parentAdminInfo({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Parent/GetAccountDetials?userId=${userId}`,
          `GET`
        );
        commit("setParentInfo", response.data.data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async parentAdminSubscriptionInfo({ commit }, userId) {
      try {
        const response = await makeApiCall(
          `Parent/GetSubscriptionParentByUserId?userId=${userId}`,
          `GET`
        );
        commit("setParentAdminSubscriptionInfo", response.data.data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async UpdateAccountDetail({ commit }, payload) {
      try {
        const response = await makeApiCall(`Parent`, `PUT`, payload);

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async SignUp({ commit }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}Auth/SignUp`,
          payload
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            commit("setSignUpDate", res);
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async CheckOutSession({ commit }, payload) {
      try {
        const response = await makeApiCall(
          `Stripe/CheckOutSession`,
          `POST`,
          payload
        );
        if (response.status) {
          var res = response.data;
          if (res.status) {
            return res;
          }
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setSignUpDate(state, signUpDate) {
      state.signUpDate = signUpDate;
    },
    setParentInfo(state, payload) {
      state.parentinfo = payload;
    },
    setParentAdminSubscriptionInfo(state, payload) {
      state.parentSubscriptionInfo = payload;
    },
  },
};
