/* eslint-disable no-unused-vars */

import { makeApiCall } from "../apiRequest";

export default {
  state: {
    childrenList: null,
  },
  getters: {
    getChildren: (state) => state.childrenList,
  },
  actions: {
    async getAllRetiredChildren({ commit, rootState }, pagination) {
      const response = await makeApiCall(
        `Pupil/GetAllRetiredPupilsByUser?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&searchString=${pagination.searchString}&userId=${pagination.userId}`,
        `GET`
      );

      if (response.status) {
        var res = response.data;
        if (res.status && res.data != null && res.data.pupils.length >= 0) {
          commit("setChildrenList", res.data.pupils);
          commit("setItemPerPage", res.data.totalItems);
        }
      }

      return response.data;
    },
    async getAllChildren({ commit, rootState }, pagination) {
      const response = await makeApiCall(
        `Pupil/GetAllPupilsByUser?pageNo=${pagination.page}&pageSize=${pagination.pageSize}&userId=${pagination.parentId}&searchString=${pagination.searchString}`,
        `GET`
      );
      if (response.status) {
        var res = response.data;
        if (res.status && res.data != null && res.data.pupils.length >= 0) {
          
          commit("setChildrenList", res.data.pupils);
          commit("setItemPerPage", res.data.totalItems);
        }
      }

      return response.data;
    },
    async addChildren({ commit }, payload) {
      try {
        const response = await makeApiCall(`Pupil`, `POST`, payload);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  mutations: {
    setChildrenList(state, payload) {
      state.childrenList = payload;
    },
  },
};
